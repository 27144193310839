<template>

<div class="panel">

    <div class="navopora" >
        <div class="electro" >
            <div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"   @change="opora=1" @click="electro">
  <label class="form-check-label" for="flexRadioDefault1">
    <b>ЭЛЕКТРООПОРА</b>
          
            </label>
            </div>
        </div>
        <div class="depo">
            <div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  @change="opora=2" @click="depo">
  <label class="form-check-label" for="flexRadioDefault2">
    <b>ТР.ОПОРА</b>

  </label>
            </div>
        </div>
    
    </div>


<div class="region" v-show="isRegion">
  <b>Регион: <input type="text" list="reg" placeholder="Выбрать регион" v-model="selectedValue"></b>
  <datalist id="reg">
    <option value="БАСЫ"></option>
    <option value="БАРАНОВКА"></option>
    <option value="ТОПОЛЯ"></option>
    <option value="ОФИС"></option>
  </datalist>
</div>

<div class="editopora">
    <b>№ опоры: <input type="text" v-model="num"></b>

    </div>

<!--Поиск улицы -->

<div class="search_street">

   <b>Улица: <input class="form-control me-2" type="search" placeholder="Поиск" aria-label="Search" @keyup='loadSuggestions' v-model='searchText' id="seid"></b>

    </div>

<div class='suggestionlist' v-if="suggestiondata.length" >

<ul > 
<li v-for= '(item,index) in suggestiondata' @click='itemSelected(index)' :key="item.id" > 
{{ item.streetname }} 
</li> 
</ul>

</div>

<div class="element">
    
    <div class="form-check">
  <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked @change="elem='ящик'">
  <label class="form-check-label" for="exampleRadios1">
    ЯЩИК
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" @change="elem='муфта'">
  <label class="form-check-label" for="exampleRadios2">
    МУФТА
  </label>
</div>

<button class="btn btn-info" @click="delete_opora">ОТМЕНИТЬ</button>
<button class="btn btn-danger" @click="$router.push('/home')">ВЫХОД</button>
<button class="btn btn-success" @click="show_opora(this.opora=1)" >ОТОБРАЗИТЬ ЭЛЕКТРОПОРЫ</button>
<button class="btn btn-dark" @click="show_opora(this.opora=2)" >ОТОБРАЗИТЬ ДЕПООПОРЫ</button>
</div>

</div>

<div class="tableopora" v-show="isVisible">
    <div class="mytable">
<table  >
  <thead>
  <tr>
    <th>
      № 
    </th>
    <th>№ опоры</th>
     <th>УЛИЦА</th>
    <th>ЭЛЕМЕНТ</th>
    <th>ШИРОТА</th>
    <th>ДОЛГОТА</th>
  </tr>
  </thead>
  <tbody>
  <tr v-for="(itm,index) of opr" :key="itm.id">
    <td>{{ index+1 }}</td>
    <td>{{itm.number}}</td>
    <td>{{itm.adres}}</td>
    <td>{{itm.element}}</td>
      <td>{{itm.latitude}}</td>
      <td>{{itm.longitude}}</td>
  </tr>
 </tbody>
</table>

</div>

</div>

<yandex-map
      
      :settings="settings"
      :coords="coords"
      :zoom="14"
      style="width: 100%; height: 930px"
      @click="opora_s"
      
      >

<!--Отображать электроопору с ящиком-->


<div v-for="op in opr" :key="op.id" >
<div v-if="opora==1 & op.element=='ящик'">
<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:icon='elbox2'
:options="{preset: 'islands#nightStretchyIcon'}"
:properties="{iconContent: 'Ящик'}"
:hint-content="op.adres+ ' Номер опоры: ' +op.number"

/>

</div>
</div>

<!--Отображать электроопору с муфтой-->


<div v-for="op in opr" :key="op.id" >
<div v-if="opora==1 & op.element=='муфта'">
<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:icon='elmufta2'
:options="{preset: 'islands#violetStretchyIcon'}"
:properties="{iconContent: 'Муфта'}"
:hint-content="op.adres+ ' Номер опоры: ' +op.number"

/>

</div>
</div>


<!--Отображать депоопору с ящиком-->


<div v-for="op in opr" :key="op.id" >
<div v-if="opora==2 & op.element=='ящик'">
<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:icon='depobox4'
:options="{preset: 'islands#blueMassTransitIcon'}"

:hint-content="op.adres+ ' Номер опоры: ' +op.number +  op.element"

/>

</div>
</div>

<!--Отображать депоопору с муфтой-->


<div v-for="op in opr" :key="op.id" >
<div v-if="opora==2 & op.element=='муфта'">
<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:options="{preset: 'islands#blueRapidTransitIcon'}"
:icon='depomufta4'
:hint-content="op.adres+ ' Номер опоры: ' +op.number + op.element"

/>

</div>
</div>


      </yandex-map>
</template>

<script>

const settings = {
        apiKey: 'c4928921-ad57-4083-a3e9-40a9288d9714',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      }
  
  
  import { yandexMap,ymapMarker } from 'vue-yandex-maps'
  import axios from 'axios'

export default {
    data(){
        return {
            coords: [50.911979, 34.802763],
            opr:[],
            street:[],
            suggestiondata:[],
            searchText:'',
            num:'',
            elem:'ящик',
            index:0,
            isVisible:false,
            opora:'',
            isRegion:false,
            selectedValue:''        

        }
    },
    methods:{
      electro(){this.isRegion=true,this.opora=1

      
      
      },
      depo(){this.isRegion,this.opora=2
      


      },

       show_opora() {

        axios.get('https://billing.online.sumy.ua/control/phpfor/sel_opora.php', {
      
      params: {type:this.opora
        
    }
  
  })            
          .then(response => {
  
  
       //Создаем массив с координатами
  
          this.opr=response.data
          this.isVisible=true
          console.log('opora',this.opr)
  
           
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  

       },


        opora_s(e) {


          if (this.num==''& this.searchText=='') {alert ('Поля не заполнены')}

          if (this.num!=''& this.searchText!='') {


//Получаем координаты по щелку

const cord=(e.get('coords'))

//выбираем отдельно широту 

const ln = cord[0];

//выбираем отдельно долготу 

const lg = cord[1];

// Записываем данные в таблицу



axios.get('https://billing.online.sumy.ua/control/phpfor/opora_temp.php', {

params: {
  
  latitude:ln, //координаты широты
  longitude:lg,//координаты долготы
  number:this.num,//номер опоры
  adres:this.searchText,//адрес опоры
  element:this.elem,// муфта или делитель
  typer:this.opora,//тип опоры(электроопора(1),деопопора(2))
  region:this.selectedValue//Выберете регион
},

})            
.then(response => {
 

  

  this.num=''
  this.show_opora()



 
})
.catch(function (response) {
  //handle error
  console.log(response)
  
})   

         }

},

//Поиск улицы в таблице улиц

loadSuggestions(){
             var el = this;
             this.suggestiondata = [];
    
             if(this.searchText != ''){
    
                axios.get('https://billing.online.sumy.ua/control/phpfor/search_street.php', {
                   params: {
                      search: this.searchText
                   }
                })
                .then(function (response) {
                    
                    el.suggestiondata = response.data;
                });
    
             }
    
          },
          itemSelected: function(index){
             var name = this.suggestiondata[index].streetname;
         
    
    
             this.searchText = name;
             this.suggestiondata = [];
    
       },

       //удаление последней записи в таблице


       delete_opora(){    
        
        
        axios.get('https://billing.online.sumy.ua/control/phpfor/delete_opora.php', {
        
         params: {oper:'alone'
        }
        })            
             .then(response => {
                 
            this.show_opora()       
              
           })
           .catch(function (response) {
               //handle error
               console.log(response)
               
           });
        

          }



    },
    components: { yandexMap,ymapMarker}


}

</script>

<style scoped>


.navopora {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    margin-left: 75%;
    margin-top: 2%;
}

.electro {
    min-height: 70px;
    min-width: 70px;
    margin-left: 30%;
    margin-top: 10%;
    width: 50px;
    cursor: pointer;
}

.depo {
    min-height: 70px;
    min-width: 70px;
    cursor: pointer;
    margin-left: 70%;
    margin-top: 10%;
    width: 10px;
}

.region {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    margin-left: 74%;
    margin-top: 5%;
}

.editopora {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    margin-left: 74%;
    margin-top: 7%;
}

.element {
    position: absolute;
    z-index: 2;
    margin-left: 74%;
    margin-top: 13%;
    border: 2px solid black;
    border-radius: 6px;
    background-color:rgb(184, 225, 241);
    padding-left: 8px;
    padding-right: 8px;
    color: black;
    font-weight: bold;
    width: 15%;
    
}


.search_street {
  position: absolute;
    z-index: 2;
    margin-left: 74%;
    margin-top: 9%;
    padding-left: 8px;
    padding-right: 8px;
    color: black;
    font-weight: bold;
    width: 270px;
}



.tableopora {
    position: absolute;
    z-index: 2;
    margin-left: 54%;
    margin-top: 25%;
}

button {
    margin-left: 5px;
    box-shadow: 1px 1px 1px black;
    margin-top: 5px;
    margin-bottom: 10px;
}


autocompleteel,.autocompleteel .suggestionlist{
       width: 300px;
    }
    
    .autocompleteel input[type=text]{
       width: 100%;
       padding: 5px;
       
    }
    
    .suggestionlist{
       position: absolute;
       z-index: 3;
       margin-top: 8%;
    }
    
    .suggestionlist ul{
     
       width: 100%;
       background: whitesmoke;
       list-style: none;
       margin: 0;
       padding: 2px;
       margin-top: 37%;
       margin-left: 66%;
    }
    
    .suggestionlist ul li{
       background: white;
       padding: 4px;
       margin-bottom: 1px;
    }
    
    .suggestionlist li:not(:last-child){
       border-bottom: 1px solid #cecece;
    }
    
    .suggestionlist ul li:hover{
       cursor: pointer;
       background: whitesmoke;
    }

    .mytable {
  position: absolute;
  z-index: 2;
  margin-left: 12%;
  margin-top: 3%;
  height: 200px;
  overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
 }
 
 .mytable::-webkit-scrollbar {
      width: 0;
      height: 0;
}

 table {
  width: 80%;
  border-collapse: collapse;
  text-align: center;
}

thead {
  position: sticky;
  top: 0;
 
}

th {
  background-color:rgb(1, 1, 8);
  color: white;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

td, th {
  padding: 8px;
  border: 1px solid #ddd;
 
}

td {
  background-color:white;
}







@media screen and (max-width: 1280px) {

  .element {
    position: absolute;
    z-index: 2;
    margin-left: 74%;
    margin-top: 17%;
    border: 2px solid black;
    border-radius: 6px;
    background-color:rgb(241, 240, 184);
    padding-left: 8px;
    padding-right: 8px;
    color: black;
    font-weight: bold;
    width: 25%;
    
}
 

.suggestionlist ul{
     
     width: 100%;
     background: whitesmoke;
     list-style: none;
     margin: 0;
     padding: 2px;
     margin-top: 37%;
     margin-left: 310%;
  }

  .editopora {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    margin-left: 74%;
    margin-top: 9%;
}


.search_street {
  position: absolute;
    z-index: 2;
    margin-left: 74%;
    margin-top: 11%;
    padding-left: 8px;
    padding-right: 8px;
    color: black;
    font-weight: bold;
    width: 270px;
}


.tableopora {
    position: absolute;
    z-index: 2;
    margin-left: 4%;
    margin-top: 2%;
}




}





</style>