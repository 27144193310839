import { createWebHistory, createRouter } from "vue-router"


import PageRegistration from './views/PageRegistration'
import MainPage from './views/MainPage'
import ActivPage from './views/ActivPage'
import AdminPage from './views/AdminPage'
import EditMagMap from './components/EditMagMap'
import EditOpora from './components/OporaPage'
import EditTelegram from './views/TelegramManager'
import ArchBase from './views/ArchBase'
import BazaTelegramClient from './components/BazaTelegramClient'
import OnlineMaps from './views/MapingOnline'
import TemplateMagistra from './components/TemplateMagistra'


const routes = [


    {
        path: "/",
        name: "PageRegistration",
        component: PageRegistration
      },



    //Главная страница
 
    {
        path: "/home",
        name: "MainPage",
        component: MainPage,
      },

    {
        path: "/activ",
        name: "ActivePage",
        component: ActivPage,
      },
   
      {
        path: "/admin",
        name: "AdminPage",
        component: AdminPage,
      }, 
      
      {
        path: "/editmag",
        name: "EditMagMap",
        component: EditMagMap,
      }, 
      
      {
        path: "/editopora",
        name: "EditOpora",
        component: EditOpora,
      }, 

      {
        path: "/telegram",
        name: "EditTelegram",
        component: EditTelegram,
      },
        
      {
        path: "/arch",
        name: "ArchBase",
        component: ArchBase,
      },

      {
        path: "/bazatelegram",
        name: "BazaTelegramClient",
        component: BazaTelegramClient,
      },

      {
        path: "/maps",
        name: "OnlineMaps",
        component: OnlineMaps,
      },

      {
        path: "/template",
        name: "TemplateMagistra",
        component: TemplateMagistra,
      },

       
       
  
  ];


const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  export default router;