<template>

<ul>

 <form class="d-flex" role="search" @submit.prevent>           
            
            <input class="form-control me-2" type="search" placeholder="Поиск" aria-label="Search" @keyup='loadSuggestions' v-model='searchText'>
            <button class="btn btn-outline-success" @click="result">ПОИСК</button>

             <div class='suggestionlist' v-if="suggestiondata.length" >
    
              <ul > 
                <li v-for= '(item,index) in suggestiondata' @click='itemSelected(index)' :key="item.id" > 
    {{ item.realname }} 
                </li> 
              </ul>
            </div> 
    
    
    
          </form>
</ul>

</template>

<script>

 import axios from 'axios'  

export default {



    data(){

    
        return{

        index:0,
        searchText:'',
        suggestiondata:[],
        login:'',
        search_delit:[]

        }
    },
    methods:{
        result(){
         
                 this.$emit('search-data',this.login )
        },
    
    
    //Autocompleate
    
    loadSuggestions(){
             var el = this;
             this.suggestiondata = [];
    
             if(this.searchText != ''){
    
                axios.get('https://billing.online.sumy.ua/control/phpfor/search.php', {
                   params: {
                      search: this.searchText
                   }
                })
                .then(function (response) {
                    
                    el.suggestiondata = response.data;
                });
    
             }
    
          },
          itemSelected: function(index){
             var name = this.suggestiondata[index].realname;
             var login = this.suggestiondata[index].login;
             this.login=login
    
    
             this.searchText = name;
             this.suggestiondata = [];
    
    
          
    
       },
    }

}
</script>

<style scoped>

.autocompleteel,.autocompleteel .suggestionlist{
       width: 300px;
    }
    
    .autocompleteel input[type=text]{
       width: 100%;
       padding: 5px;
    }
    
    .suggestionlist{
       position: absolute;
       z-index: 3;
       margin-top: 3%;
    }
    
    .suggestionlist ul{
       width: 100%;
       background: whitesmoke;
       list-style: none;
       margin: 0;
       padding: 5px;
    }
    
    .suggestionlist ul li{
       background: white;
       padding: 4px;
       margin-bottom: 1px;
    }
    
    .suggestionlist li:not(:last-child){
       border-bottom: 1px solid #cecece;
    }
    
    .suggestionlist ul li:hover{
       cursor: pointer;
       background: whitesmoke;
    }








</style>