<template>

<div class="awake" v-show="isAwake">
  
  <h3 >Для пользование программой пожалуйста авторизируйтесь <a href="https://scada.online.sumy.ua">https://scada.online.sumy.ua</a></h3>
  
  
  </div>



  <div class="card" style="width: 38rem;" v-show="isOrder"  >
  <div class="card-body">
    <h5 class="card-title">Администрирование справочников</h5>
  <ul>

 <li>
      <button class="btn btn-secondary" @click="$router.push('/editopora')">РЕДАКТОР БАЗЫ ЭЛЕКТРО И ТРАНСПОРТНЫХ ОПОР</button>
    </li>
 <li>
      <button class="btn btn-dark">БАЗА МАГИСТРАЛЬНЫХ ДЕЛИТЕЛЕЙ</button>
    </li>

    <li>
      <button class="btn btn-primary" @click="editmap">РЕДАКТОР МАГИСТРАЛЕЙ НА КАРТЕ</button>
    </li>
    <li>
      <button class="btn btn-success" @click="$router.push('/bazatelegram')">РЕДАКТОР БАЗЫ АБОНЕНТОВ ДЛЯ РАССЫЛКИ</button>
    </li>
   
    <li>
      <button class="btn btn-dark" @click="$router.push('/template')" >НАСТРОЙКА ШАБЛОНОВ МАГИСТРАЛЕЙ</button>
    </li>
   


   
    <li>
      <button class="btn btn-danger" @click="$router.push('/home')">ВЫХОД</button>
    </li>


  </ul>
  </div>
</div>

  

</template>

<script>
export default {
  data(){
    return {
      isOrder:true,
      isAwake:false
    }

  },

beforeMount(){
  

  const fio=localStorage.getItem('fio')

if(fio==''){
 
  this.isOrder=false
  this.isAwake=true
}




},

  
  beforeUpdate(){

const fio=localStorage.getItem('fio')

if(fio==''){
 
  this.isOrder=false
  this.isAwake=true
}


    },
  methods:{
    editmap(){
      this.$router.push('/editmag')
    }
  }

}
</script>

<style scoped>

.card {
  margin-left: 40%;
  margin-top: 10%;
}

button {
  margin-top: 2px;
}

.card-title {
  text-align: center;
}

li {
  list-style-type:none;
}

h3{
  text-align: center;
  margin-top: 10%;
}


</style>