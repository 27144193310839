<template>

<div class="card" style="width: 20rem;" v-show="isInsert">

  <div class="card-body">
    <h5 class="card-title">Вставка изображения</h5>
    <p class="card-text"> <input type="text" v-model="name" placeholder="Название">               </p>
    <p class="card-text">    <input type="text" v-model="description" placeholder="Описание">            </p>
    <p class="card-text">  <input type="file" @change="uploadImage">              </p>

   <!---- <a href="#" class="btn btn-danger" @click="isInsert=false">ВЫХОД</a> -->
  </div>
</div>

  </template>
  
  <script>

import axios from 'axios';

import '../components/ListPhoto.vue'

  export default {
    props:['num_opora','street_opora'],
    data(){
        return {
            name:'',
            description:'',
            image:null,
            isInsert:true
        }
    },

    methods: {
      uploadImage(event) {
        // Получение файла из элемента input
        const file = event.target.files[0];
        
        // Создание объекта FormData для отправки файла на сервер
        const formData = new FormData();
        formData.append('image', file);
        formData.append('name', this.name);
        formData.append('description', this.description);
        formData.append('number', this.num_opora);
        formData.append('street', this.street_opora);

  
        // Отправка запроса на сервер
        axios.post('https://bas.online.sumy.ua/upload_image.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if (response.data.success) {
      alert('Изображение загружено успешно');
      this.name=''
      this.description=''
      this.image=''
      

      
    }
          // Обработка ответа от сервера
          console.log(response.data);
        })
        .catch(error => {
          // Обработка ошибки
          console.error(error);
        });
      }
    }
  }


  </script>

<style scoped>


.card {
  position: absolute;
  z-index: 3;
}


</style>