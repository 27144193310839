<template>

<!--Модуль удаленные абоненты-->

  <div class="card">
  <div class="card-header">
    <h3>ИНФОРМАЦИЯ ПО УДАЛЕННЫМ АБОНЕНТАМ</h3>
  </div>
  <div class="card-body">
    <h5 class="card-title">Таблица удаленных абонентов</h5>
    <p class="card-text">

        <table>
  
  <tr>
    <th>№ </th>
     <th>Логин</th>
    <th>Абонент</th>
     <th>Адрес</th>
    <th>Ип</th>
    <th>Мак роутера</th>
    <th>Мак ону</th>
    <th>Остаток</th>
    <th>№ делителя</th>
    <th>Порт</th>
    <th>Ип коммутатора</th>
    <th>Адрес делителя</th>
    <th>Причина удаления</th>
    <th>Дата удаления</th>
   
  </tr>
 
  <tr v-for="(itm,dex) of deleting" :key="itm.id">
    <td>{{ dex+1 }}</td>
    <td>{{itm.login}}</td>
    <td>{{itm.realname}}</td>
    <td>{{itm.streetname}} {{ itm.buildnum }}</td>
    <td>{{itm.ip}}</td>
    <td>{{itm.mac_router}}</td>
    <td>{{itm.mac_onu}}</td>
    <td>{{itm.cash}} грн</td>
    <td>{{itm.number}}</td>
    <td>{{itm.port}}</td>
    <td>{{itm.ipolt}}</td>
    <td>{{itm.adres}}</td>
    <td>{{itm.causa}}</td>
    <td>{{itm.data_oper}}</td>
   
  
    </tr>

</table>



    </p>
  
  </div>
</div>
</template>

<script>

import axios from 'axios';

export default {
    data(){
        return {
            deleting:[]// массив удаленных абонентов
        }
    },
    updated(){



        axios.get('https://billing.online.sumy.ua/control/phpfor/tabledel.php', {

params: {
 
 
}
})            
   .then(response => {

    this.deleting=response.data 
    
 })
 .catch(function (response) {
     //handle error
     console.log(response)
     
 });   
    }

}
</script>

<style scoped>


.card {
    position: absolute;
    z-index: 3;
    margin-left: 1%;
    margin-right: 1%;
    box-shadow: 3px 1px 1px black;
    
}


.card-body {
    background-color: rgb(19, 18, 2);
}

h5{
    text-align: center;
    color:white;
}


table {
    
    margin-top: 10px;
    border-collapse: collapse;
    background-color:#e2ecfb;
    text-align: center;
  
  }
  tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 370px;
  }
 thead tr {
    display: block;
  }
 thead {
   
    color: #fff;
    text-align: center;     
    background: #00020a;
    text-shadow: 1px 1px 1px black;

  
  }
  th,
  td {
    padding: 5px;
    text-align:center;
    width: 800px;
    border: 1px solid #020411;
  }



td {
    background-color: white;
    text-align: center;
    margin-left: 2px;
    margin-right: 2px;
}

tr {
    margin-left: 2px;
    margin-right: 2px;

}

h3{
    text-align: center;
}


</style>