<template>

<div class="basecontainer">
<div class="container1">

    <h5>МАГИСТРАЛИ ПРИХОДА</h5>
    
    <magistra-info class="module" ident="БАСЫ ОСНОВНОЙ"></magistra-info>
    <magistra-info class="module" ident="БАСЫ РЕЗЕРВНЫЙ"></magistra-info>
    <magistra-info class="module" ident="БАРАНОВКА   "></magistra-info>
    <magistra-info class="module" ident="ТОПОЛЯНСКАЯ "></magistra-info>
  
</div>

<div class="container2">

<h5>КЛИЕНТСКИЕ МАГИСТРАЛИ : БАСЫ(10.79.254.231)</h5>

<magistra-info class="module" ident="ПЕРВЫЙ ПОРТ-31"></magistra-info>
<magistra-info class="module" ident="ВТОРОЙ ПОРТ-31"></magistra-info>
<magistra-info class="module" ident="ТРЕТИЙ ПОРТ-31"></magistra-info>
<magistra-info class="module" ident="ЧЕТВЕРТЫЙ ПОРТ-31"></magistra-info>

</div>

<div class="container3">

<h5>КЛИЕНТСКИЕ МАГИСТРАЛИ : БАСЫ(10.79.254.229)</h5>

<magistra-info class="module" ident="ПЕРВЫЙ ПОРТ-29"></magistra-info>
<magistra-info class="module" ident="ВТОРОЙ ПОРТ-29"></magistra-info>
<magistra-info class="module" ident="ТРЕТИЙ ПОРТ-29"></magistra-info>
<magistra-info class="module" ident="ЧЕТВЕРТЫЙ ПОРТ-29"></magistra-info>

</div>

<div class="container4">

<h5>КЛИЕНТСКИЕ МАГИСТРАЛИ : ТОПОЛЯНСКАЯ (10.79.254.240)</h5>

<magistra-info class="module" ident="ПЕРВЫЙ ПОРТ-40"></magistra-info>
<magistra-info class="module" ident="ВТОРОЙ ПОРТ-40"></magistra-info>
<magistra-info class="module" ident="ТРЕТИЙ ПОРТ-40"></magistra-info>
<magistra-info class="module" ident="ЧЕТВЕРТЫЙ ПОРТ-40"></magistra-info>

</div>

<div class="container5">

<h5>КЛИЕНТСКИЕ МАГИСТРАЛИ : ТОПОЛЯНСКАЯ (10.79.254.244)</h5>

<magistra-info class="module" ident="ПЕРВЫЙ ПОРТ-44"></magistra-info>
<magistra-info class="module" ident="ВТОРОЙ ПОРТ-44"></magistra-info>
<magistra-info class="module" ident="ТРЕТИЙ ПОРТ-44"></magistra-info>
<magistra-info class="module" ident="ЧЕТВЕРТЫЙ ПОРТ-44"></magistra-info>

</div>

<div class="container6">

<h5>КЛИЕНТСКИЕ МАГИСТРАЛИ : БАРАНОВКА(10.79.254.245)</h5>

<magistra-info class="module" ident="ПЕРВЫЙ ПОРТ-45"></magistra-info>
<magistra-info class="module" ident="ВТОРОЙ ПОРТ-45"></magistra-info>
<magistra-info class="module" ident="ТРЕТИЙ ПОРТ-45"></magistra-info>
<magistra-info class="module" ident="ЧЕТВЕРТЫЙ ПОРТ-45"></magistra-info>

</div>
 

<div class="container7">

<h5>КЛИЕНТСКИЕ МАГИСТРАЛИ : ОФИС (10.79.254.246)</h5>

<magistra-info class="module" ident="ПЕРВЫЙ ПОРТ-46"></magistra-info>
<magistra-info class="module" ident="ВТОРОЙ ПОРТ-46"></magistra-info>
<magistra-info class="module" ident="ТРЕТИЙ ПОРТ-46"></magistra-info>
<magistra-info class="module" ident="ЧЕТВЕРТЫЙ ПОРТ-46"></magistra-info>

</div>

<div class="panels">

  <table  >
  <thead>
  <tr>
    <th>
      № 
    </th>
    <th>Название магистрали</th>
    <th>Привязана</th>
  </tr>
  </thead>
  <tbody>
  <tr v-for="(item,index) of magica" :key="item.id">
    <td>{{ index+1 }}</td>
      <td>{{item.name_magistral}}</td>
      <td>{{item.template}}</td>
  </tr>
 </tbody>
</table>

<button class="btn btn-success" @click="tables">ОБНОВИТЬ</button>
<button class="btn btn-danger" @click="$router.push('/home')">ВЫЙТИ</button>

</div>

</div>

</template>

<script>

import axios from 'axios'

import MagistraInfo from '../components/MagistraInfo.vue'

export default {
  data(){
    return {
      magica:[]
    

    }
  },

  mounted(){
    this.tables()
  },

  components: {MagistraInfo},

  methods:{
    tables(){

     

      axios.get('https://billing.online.sumy.ua/control/phpfor/infotable.php', {
      
      params: {
        
    }
  
  })            
          .then(response => {
  
  
       //Создаем массив с координатами
  
       this.magica=Object.keys(response.data).map(key=>{
                  return {
                    id:key,
                    ...response.data[key]
                  }      
                }) 
                
        
        
         
  
           
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  
    }
  },

 

}
</script>

<style scoped>

.basecontainer {
  display: flex;
  flex-wrap: wrap;

  align-content: space-between;
}

.container1 {

  padding: 0;
  margin: 0;
  margin-left: 0.5%;
  margin-top: 0.3%;
  margin-bottom:0.3% ;
  border: 1px solid;
  border-radius: 14px;
  width: 40%;
  background-color: rgb(140, 224, 91);
}

.container2 {

  padding: 0;
  margin: 0;
  margin-left: 0.5%;
  margin-top: 0.3%;
  margin-bottom:0.3% ;
  border: 1px solid;
  border-radius: 14px;
  width: 40%;
  background-color: rgb(71, 157, 238);
}

.container3 {
 
  padding: 0;
  margin: 0;
  margin-left: 0.5%;
 
  margin-bottom:0.3% ;
  border: 1px solid;
  border-radius: 14px;
  width: 40%;
  background-color: rgb(210, 216, 123);
}

.container4 {
  
  padding: 0;
  margin: 0;
  margin-left: 0.5%;
  margin-bottom:0.3% ;
  border: 1px solid;
  border-radius: 14px;
  width: 40%;
  background-color: rgb(189, 72, 72);
}

.container5 {
  
  padding: 0;
  margin: 0;
  margin-left: 0.5%;
 
  border: 1px solid;
  border-radius: 14px;
  width: 40%;
  background-color: rgb(231, 176, 224);
}

.container6 {
  
  padding: 0;
  margin: 0;
  margin-left: 0.5%;
 
  border: 1px solid;
  border-radius: 14px;
  width: 40%;
  background-color: rgb(231, 176, 224);
}

.container7 {
  
  padding: 0;
  margin: 0;
  margin-left: 0.5%;
  margin-top: 0.3%;
  border: 1px solid;
  border-radius: 14px;
  width: 40%;
  color:white;
  background-color: rgb(34, 36, 110);
}

.panels {
  
  padding: 0;
  margin: 0;
  margin-left: 0.5%;
  margin-top: 0.3%;
  border: 1px solid;
  border-radius: 14px;
  width: 40%;

  background-color: rgb(34, 36, 110);
}


.module {
  margin-bottom: 1%;
}

h5{
  text-align: center;
}

.btn {
  margin-left: 2%;
  margin-top: 0.3%;
  text-shadow: 1px 1px 1px black;
}

table {
  width: 98%;
  border-collapse: collapse;
  text-align: center;
  margin-top: 1%;
  margin-left: 1%;
  margin-right: 1%;
}

thead {
  position: sticky;
  top: 0;
 
}

th {
  background-color:rgb(1, 1, 8);
  color: white;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

td, th {
  padding: 8px;
  border: 1px solid #ddd;
 
}

td {
  background-color:white;
}

</style>