<template>

<div class="awake" v-show="isAwake">
  
  <h3 >Для пользование программой пожалуйста авторизируйтесь <a href="https://scada.online.sumy.ua">https://scada.online.sumy.ua</a></h3>
  
  
  </div>

<div class="order" v-show="isOrder">

    <reg-component></reg-component>
    
    <div class="activ_sess">
        
    <nav class="navbar navbar-dark bg-dark">
      <div class="container-fluid">
        <h1 class="navbar-brand">АКТИВНЫЕ СЕССИИ</h1>
        <h1 class="status" v-show="isVisible">СЕССИЯ ОБОРВАНА</h1>
        <b class="ses">ВСЕГО АВТОРИЗИРОВАЛИСЬ: <b class="ses_date">{{col_ses}}</b> </b>
      </div>
    </nav>
    
      <div id="app">
      <table id="ses_table">
    
        <thead>
          <tr>
            <th>Логин</th>
          <th>Абонент </th>
    <th>Адрес</th>
    <th>Дом</th>
    <th >Ип</th>
    <th>Мак</th>
    <th>Пинг клиента</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in filteredRows" :key="`employee-${index}`">
             <td v-html="highlightMatches(row.login)" ></td>
             <td v-html="highlightMatches(row.realname)"></td>
             <td v-html="highlightMatches(row.streetname)"></td>
             <td v-html="highlightMatches(row.buildnum)"></td>
             <td title="Для запроса дважды щелкните мышкой" style="background-color:greenyellow;cursor: pointer;color:black;opacity:0.7" v-html="highlightMatches(row.ip)" @click="id_activ"></td>
             <td v-html="highlightMatches(row.mac)"></td>
             <td><button @click="ipa(row.ip)" >PING</button></td>
     
          </tr>
        </tbody>
      </table>
    
    <div class="search">
    
    <input type="search"
             class="form-control"
             id="znk"
             placeholder="Поиск клиента по логину,имени,адресу и ип"
             v-model="filter" />
    </div>
    
    <div class="manual">
    

<div class="pingshow">
  
  <h4>{{ pingshow }}</h4>

</div>



    <div class="card-body list">
    
    <ul class="first">
    
    <li><b>Абонент </b>{{fio}} </li>
    <li><b>Адрес </b>{{adress}} {{dom}} </li>
    <li><b>ИП </b>{{ip}}</li>
    <li><b>МАК </b>{{mac}} <b> Мак в сессии <b style="color:darkblue">{{mac_ses}}</b></b> </li>
    <li><b>№ сессии </b>{{num_sess}} </li>
    <li><b>Время сессии </b><b class="ts">{{time_ses}}</b></li>
    
    </ul>
    
    </div>
    
    <div class="button_block">
    
    <button type="button" data-tooltip="эта подсказка длиннее, чем элемент" class="btn btn-primary" @click="break_ses">ОБОРВАТЬ СЕССИЮ</button>
    <button type="button" class="btn btn-secondary" @click="activ">ОБНОВИТЬ ДАННЫЕ</button>
    <button type="button" class="btn btn-danger" @click="exit">ВЫХОД</button>
    
    </div>
    
    <div class="grafik_sess">
    
    </div>
    
      </div>
    
    </div>
    
    </div>
    <futer-info class="footer"></futer-info>
    
    
</div>

    </template>
    
    <script>
    
    import axios from 'axios'

    import jQuery from "jquery";
    const $ = jQuery;
    window.$ = $;
    
    $(document).on('click','td',function(){
        var ses_data = $(this).text(); 
       localStorage.setItem('activ',ses_data)
      
        
    });
    
    
    
    export default {
        el: '#app',
      data() {
          return {  
        isVisible:false,    
        filter:'',
        col_ses:0,
        current_item:'',
        fio:'',
        adress:'',
        dom:'',
        ip:'',
        mac:'',
        num_sess:'',
        time_ses:'',
        mac_ses:'',
        mynumber:'',
        rows: [],
        ses:[],
        id_ses:[],
        ses_break:[],
        pingshow:'',
        isOrder:true,
      isAwake:false
        
          }
      },  
      
      beforeUpdate(){

const fio=localStorage.getItem('fio')

if(fio==''){
  this.isOrder=false
  this.isAwake=true
}



    },
 
    
      methods: {
          highlightMatches(text) {
        const matchExists = text.toLowerCase().includes(this.filter.toLowerCase());
        if (!matchExists) return text;
    
        const re = new RegExp(this.filter, 'ig');
        return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);
      },


//пинговалка клиента      

    ipa(ips) {
     

      axios.get('https://bas.online.sumy.ua/cisco/ping.php', {
    
    params: {ip:ips
  }
})            
        .then(response => {
            
          this.pingshow=response.data

          setTimeout(() => this.pingshow='', 6000);
    
         
      })
      .catch(function (response) {
          //handle error
          console.log(response)
          
      });
 
      

    },



    
    exit() {
      this.$router.push('/home')
       localStorage.setItem('ident','')
      localStorage.setItem('activ','')
    
    
    },
    
    id_activ(){
          
      this.mac_ses=''
      this.time_ses=''
      this.num_sess=''
    
      const value=localStorage.getItem('activ')

    axios.get('https://billing.online.sumy.ua/control/phpfor/activ_fio.php', {
    
          params: {ip:value
        }
      })            
              .then(response => {
                  
           this.id_ses=Object.keys(response.data).map(key=>{
                    return {
                      id:key,
                      ...response.data[key]
                    }
                  })         
            

    //Выводим ФИО клиента
    
    const fio=this.id_ses.map(a=>a.realname)
    
    this.fio=fio.toString()
    
    //Выводим улицу клиента
    
    const street=this.id_ses.map(b=>b.streetname)
    
    this.adress=street.toString()
    
    //Выводим номер дома клиента
    
    const dom=this.id_ses.map(c=>c.buildnum)
    
    this.dom=dom.toString()
    
    //Выводим ип клиента
    
    const ip=this.id_ses.map(d=>d.ip)
    
    this.ip=ip.toString()
    
    //Выводим мак клиента
    
    const mac=this.id_ses.map(e=>e.mac)
    
    this.mac=mac.toString()
    
               
            })
            .catch(function (response) {
                //handle error
                console.log(response)
                
            });
        
    
    const valueip=localStorage.getItem('activ')
    
    
    
    axios.get('https://bas.online.sumy.ua/cisco/cisco.php', {
    
          params: {ip:valueip
        }
      })            
              .then(response => {
                  
           this.ses=Object.keys(response.data).map(key=>{
                    return {
                      id:key,
                      ...response.data[key]
                    }
                  })         
    


    
    const time_ses=this.ses.map(a=>a.time)
    const number_ses=this.ses.map(b=>b.number_ses)
    
    const mac_ses=this.ses.map(b=>b.mac_asr)
    
    this.time_ses=time_ses.toString()
    this.num_sess=number_ses.toString()
    this.mac_ses=mac_ses.toString()
    
     localStorage.setItem('nses',this.num_sess)
               
            })
            .catch(function (response) {
                //handle error
                console.log(response)
                
            });
       
    
    },
    
    break_ses(){
      var nses = localStorage.getItem('nses')
    
    axios.get('https://bas.online.sumy.ua/cisco/cisco_break.php', {
    
          params: {id_ses:nses
        }
      })            
              .then(response => {
                  
           this.ses_break=Object.keys(response.data).map(key=>{
                    return {
                      id:key,
                      ...response.data[key]
                    }
                  })         
    
    console.log(response.data)
    
    this.isVisible=true
    
    
    const valueip=localStorage.getItem('ident')
    
    
    
    axios.get('https://bas.online.sumy.ua/cisco/cisco.php', {
    
          params: {ip:valueip
        }
      })            
              .then(response => {
                  
           this.ses=Object.keys(response.data).map(key=>{
                    return {
                      id:key,
                      ...response.data[key]
                    }
                  })         
    
    
    const time_ses=this.ses.map(a=>a.time)
    const number_ses=this.ses.map(b=>b.number_ses)
    
    const mac_ses=this.ses.map(b=>b.mac_asr)
    
    this.time_ses=time_ses.toString()
    this.num_sess=number_ses.toString()
    this.mac_ses=mac_ses.toString()
    
     localStorage.setItem('nses',this.num_sess)
    
     this.isVisible=false
               
            })
            .catch(function (response) {
                //handle error
                console.log(response)
                
            });
    
    
    
               
            })
            .catch(function (response) {
                //handle error
                console.log(response)
                
            });
    
    
    
    
    },
    
    
      activ(){
           axios.get('https://billing.online.sumy.ua/control/phpfor/activ.php', {
    
        
      })
      .then(response=> {
          this.rows=Object.keys(response.data).map(key=>{
                    return {
                      id:key,
                      ...response.data[key]
                    }      
                  }) 
                  
                  this.col_ses=this.rows.length
       
      })
    }
        
      },
    
    
    
    
      computed: {
          filteredRows() {
        return this.rows.filter(row => {
          const employees = row.login.toString().toLowerCase();
          const department = row.realname.toLowerCase();
          const adres= row.streetname.toLowerCase();
          const ipadress = row.ip.toLowerCase();
    
          const searchTerm = this.filter.toLowerCase();
    
          return department.includes(searchTerm) ||
            employees.includes(searchTerm)||
            adres.includes(searchTerm)||
            ipadress.includes(searchTerm);
        });
      }
      },
    
      mounted(){
          this.activ()
          document.getElementById("znk").focus() 
      }
    
    }
    </script>
    
    <style lang="css" scoped>
    
    .activ_sess {
    
     height: 750px;
      overflow: auto;
      -ms-overflov-style:none;
      scrollbar-width: none;
    }
    
    .activ_sess::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    
    
    body {
      padding: 0;
      margin: 0;
      width: 100%;
    }
    
    html {
      width: 100%;
    }
    
    .status {
      color:crimson;
      text-shadow: 1px 1px 1px black;
    }
    
    
    
    
    table {
      
            margin-top: 10px;
            border-collapse: collapse;
            background-color:#e2ecfb;
            text-align: center;
            width: 100%;
          }
          tbody {
            display: block;
            width: 100%;
            overflow: auto;
            height: 370px;
          }
         thead tr {
            display: block;
          }
         thead {
           
            color: #fff;
            text-align: center;     
            background: #0F2394;
            text-shadow: 1px 1px 1px black;
        
           border: 1px solid #0F2394;
          }
          th,
          td {
            padding: 5px;
            text-align:center;
            width: 400px;
            border: 1px solid #0F2394;
          }
    
          tr:hover{
    background-color:#0F2394;
    color:white;
    text-shadow: 1px 1px 1px black;
    }
    
         
          tfoot {
            background-color: #0F2394;
            color: gold;
            text-shadow: 1p 1px 1px black;
          }
    
         .search {
           padding-top: 10px;
           padding-left: 10px;
           
         }
    
         input.search {
           width: 20%;
         }
    
    
         .manual {
    
      display: grid;
      grid-template-rows: 2;
      grid-template-columns:1;
    }
    
    .list {
    
      grid-column-start: 1;
      grid-column-end: 1;
    
    }
    
    .button_block {
      margin-top: 15px;
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1;
    }
    
    .grafik_sess {
      margin-top: 15px;
      grid-column-start: 3;
      grid-column-end: 3;
    }
    
    .btn {
      margin-left: 10px;
      box-shadow: 1px 1px 1px darkblue;
      text-shadow: 1px 1px 1px black;
    }
    
    .ses {
      font-size: 24px;
      color: cornflowerblue;
      text-shadow: 1px 1px 1px black;
      margin-left: -20%;
    }
    
    .ses_date {
      color:hotpink;
    
    }
    
    .ts {
      color:darkred;
      font-weight: bolder;
    }
    
    h3{
  text-align: center;
  margin-top: 10%;
}
  
    
          </style>