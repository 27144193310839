<template>



<div class="awake" v-show="isAwake">
  
  <h3 >Для пользование программой пожалуйста авторизируйтесь <a href="https://scada.online.sumy.ua">https://scada.online.sumy.ua</a></h3>
  
  
  </div>



  <div class="order" v-show="isOrder2">


  <div class="photo-browser" v-show="isPhoto">
    <photo :num_opora="num_opora" :street_opora="adr_opora"></photo>

  </div>


<div class="info_opora" v-show="isOpora">
  <ul>

    <li><b>Тип опоры:</b> {{ type_opora }}</li>
    <li><b>Номер опоры:</b> {{ num_opora }}</li>
    <li> <b>Aдрес опоры:</b> {{ adr_opora }}</li>
    <li><b>Координаты опоры:</b></li>
   

      <b>Широта:</b>{{ coord_opora_lat }}<br>
      <b>Долгота:</b>{{ coord_opora_lg }}
   
      <li><b>Базовый сетевой опоры:</b>{{ elem_opora }}</li>
  </ul>


<list :num_opora="num_opora" :street_opora="adr_opora" @snd="send"></list>

<div>

  <input type="search" textarea="Опишите содержимое" style="margin-left: 2%; width:70%" v-model="setinfo"  ><button @click="insert_info_opora(num_opora,adr_opora)">ДОБАВИТЬ</button>
  
</div>

  <table style="width: auto;margin-right: 1%;">
  
  <thead>
    <tr>
<th style="width:auto">№</th>
<th>Информация</th>
<th style="width:auto">Дата записи</th>
    </tr>
  </thead>
  <tbody style="height:auto; width: auto;">
    <tr v-for="(op,n) in opora_info" :key="op.id">
      <td style="width: auto;">{{ n+1}} </td>
       <td>{{ op.setopora}} </td>
       <td style="width: auto;">{{ op.ins_data}} </td>


    </tr>
  </tbody>
</table>

<!----<button class="btn btn-success" style="margin-top: 2px;" @click="manager_photo" >ДОБАВИТЬ ФОТОГРАФИИ</button> -->

<button class="btn" :class="[isPhoto?'btn-info':'btn-success']" @click="manager_photo" >ДОБАВИТЬ ФОТОГРАФИИ</button>

  <button class="btn btn-danger" style="margin-top: 2px;" @click="isOpora=false">ЗАКРЫТЬ</button>


</div>


<!--Блок меню-->


<div class="menu">
  <nav>
  <ul class="topmenu">

    <li><a href="#" class="active">Главная<span class="fa fa-angle-down"></span></a>
      <ul class="submenu">
       
        <li><a href="#" class="general" @click="search">Поиск</a></li>
       
        <li><a href="#" class="general" style="color: maroon;" @click="$router.push('/home')">Выход</a></li>

      </ul>
    </li>

    <li><a href="" class="active">Делители<span class="fa fa-angle-down"></span></a>
      <ul class="submenu">
        <li><a href="#" class="head">КЛИЕНТСКИЕ ДЕЛИТЕЛИ</a></li>
        <li><a href="#" class="general" @click="del">Все делители</a></li>
        <li><a href="#" @click="del_hosts('10.79.254.246','all')">Офис<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#"  @click="del_hosts('10.79.254.246',1)">Первый порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.246',2)">Второй порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.246',3)">Третий порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.246',4)">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#" @click="del_hosts('10.79.254.245','all')">Барановка<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="del_hosts('10.79.254.245',1)">Первый порт</a></li>
            <li><a href="#" @click="del_hosts('10.79.254.245',2)">Второй порт</a></li>
            <li><a href="#" @click="del_hosts('10.79.254.245',3)">Третий порт</a></li>
            <li><a href="#" @click="del_hosts('10.79.254.245',4)">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#" @click="del_hosts('10.79.254.240','all')">Тополянская(10.79.254.240)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="del_hosts('10.79.254.240',1)">Первый порт</a></li>
            <li><a href="#" @click="del_hosts('10.79.254.240',2)">Второй порт</a></li>
            <li><a href="#" @click="del_hosts('10.79.254.240',3)">Третий порт</a></li>
            <li><a href="#" @click="del_hosts('10.79.254.240',4)">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#" @click="del_hosts('10.79.254.244','all')">Тополянская(10.79.254.244)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="del_hosts('10.79.254.244',1)">Первый порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.244',2)">Второй порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.244',3)">Третий порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.244',4)">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#" @click="del_hosts('10.79.254.231','all')">Басы(10.79.254.231)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="del_hosts('10.79.254.231',1)">Первый порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.231',2)">Второй порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.231',3)">Третий порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.231',4)">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#" @click="del_hosts('10.79.254.229','all')">Басы(10.79.254.229)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#"  @click="del_hosts('10.79.254.229',1)">Первый порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.229',2)">Второй порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.229',3)">Третий порт</a></li>
            <li><a href="#"  @click="del_hosts('10.79.254.229',4)">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#" class="head"> ДЕЛИТЕЛИ</a></li>

        <li><a href="#" class="general" @click="list">Полный список делителей</a></li>
        <li><a href="#" class="general" @click="insertnew">Добавить новый делитель</a></li>
        <li><a href="#" class="general" @click="close_del" style="color: darkred;">Спрятать все</a></li>

      </ul>
    </li>

        
    <li><a href="#" class="active">Опоры<span class="fa fa-angle-down"></span></a>
      <ul class="submenu">
        <li><a href="#" class="head">ОПОРЫ ОБЛЕНЕРГО</a></li>
        <li><a href="#" class="general" @click="opora_res('all')">Все опоры</a></li>
        <li><a href="#">Офис<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#">Первый порт</a></li>
            <li><a href="#">Второй порт</a></li>
            <li><a href="#">Третий порт</a></li>
            <li><a href="#">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#" @click="opora_res('БАРАНОВКА')">Барановка<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#">Первый порт</a></li>
            <li><a href="#">Второй порт</a></li>
            <li><a href="#">Третий порт</a></li>
            <li><a href="#">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#">Тополянская(10.79.254.240)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#">Первый порт</a></li>
            <li><a href="#">Второй порт</a></li>
            <li><a href="#">Третий порт</a></li>
            <li><a href="#">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#">Тополянская(10.79.254.244)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#">Первый порт</a></li>
            <li><a href="#">Второй порт</a></li>
            <li><a href="#">Третий порт</a></li>
            <li><a href="#">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#">Басы(10.79.254.231)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="infomag('ПЕРВЫЙ ПОРТ-31')">Первый порт</a></li>
            <li><a href="#" @click="infomag('ВТОРОЙ ПОРТ-31')">Второй порт</a></li>
            <li><a href="#" @click="infomag('ТРЕТИЙ ПОРТ-31')">Третий порт</a></li>
            <li><a href="#" @click="infomag('ЧЕТВЕРТЫЙ ПОРТ-31')">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#">Басы(10.79.254.229)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="infomag('ПЕРВЫЙ ПОРТ-29')">Первый порт</a></li>
            <li><a href="#" @click="infomag('ВТОРОЙ ПОРТ-29')">Второй порт</a></li>
            <li><a href="#" @click="infomag('ТРЕТИЙ ПОРТ-29')">Третий порт</a></li>
            <li><a href="#" @click="infomag('ЧЕТВЕРТЫЙ ПОРТ-29')">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#" class="head">ОПОРЫ ДЕПО</a></li>
        <li><a href="#" class="general" @click="depo_opora">Показать опоры</a></li>
        <li><a href="#" class="general" style="color: darkred;">Спрятать все</a></li>

      </ul>
    </li>



    <li><a href="#" class="active">Магистрали<span class="fa fa-angle-down"></span></a>
      <ul class="submenu">
        <li><a href="#" class="head">КЛИЕНТСКИЕ МАГИСТРАЛИ</a></li>
        <li><a href="#" class="general">Все магистрали</a></li>
        <li><a href="#">Офис<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#">Первый порт</a></li>
            <li><a href="#">Второй порт</a></li>
            <li><a href="#">Третий порт</a></li>
            <li><a href="#">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#">Барановка<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="infomag('ПЕРВЫЙ ПОРТ-45')">Первый порт</a></li>
            <li><a href="#" @click="infomag('ВТОРОЙ ПОРТ-45')">Второй порт</a></li>
            <li><a href="#" @click="infomag('ТРЕТИЙ ПОРТ-45')">Третий порт</a></li>
            <li><a href="#" @click="infomag('ЧЕТВЕРТЫЙ ПОРТ-45')">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#">Тополянская(10.79.254.240)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="infomag('ПЕРВЫЙ ПОРТ-40')">Первый порт</a></li>
            <li><a href="#" @click="infomag('ВТОРОЙ ПОРТ-40')">Второй порт</a></li>
            <li><a href="#" @click="infomag('ТРЕТИЙ ПОРТ-40')">Третий порт</a></li>
            <li><a href="#" @click="infomag('ЧЕТВЕРТЫЙ ПОРТ-40')">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#">Тополянская(10.79.254.244)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="infomag('ПЕРВЫЙ ПОРТ-44')">Первый порт</a></li>
            <li><a href="#" @click="infomag('ВТОРОЙ ПОРТ-44')">Второй порт</a></li>
            <li><a href="#" @click="infomag('ТРЕТИЙ ПОРТ-44')">Третий порт</a></li>
            <li><a href="#" @click="infomag('ЧЕТВЕРТЫЙ ПОРТ-44')">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#">Басы(10.79.254.231)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="infomag('ПЕРВЫЙ ПОРТ-31')">Первый порт</a></li>
            <li><a href="#" @click="infomag('ВТОРОЙ ПОРТ-31')">Второй порт</a></li>
            <li><a href="#" @click="infomag('ТРЕТИЙ ПОРТ-31')">Третий порт</a></li>
            <li><a href="#" @click="infomag('ЧЕТВЕРТЫЙ ПОРТ-31')">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#">Басы(10.79.254.229)<span class="fa fa-angle-down"></span></a>
          <ul class="submenu">
            <li><a href="#" @click="infomag('ПЕРВЫЙ ПОРТ-29')">Первый порт</a></li>
            <li><a href="#" @click="infomag('ВТОРОЙ ПОРТ-29')">Второй порт</a></li>
            <li><a href="#" @click="infomag('ТРЕТИЙ ПОРТ-29')">Третий порт</a></li>
            <li><a href="#" @click="infomag('ЧЕТВЕРТЫЙ ПОРТ-29')">Четвертый порт</a></li>
          </ul>
        </li>

        <li><a href="#" class="head">МАГИСТРАЛИ ПРИХОДА</a></li>
        <li><a href="#" @click="infomag('БАРАНОВКА')">БАРАНОВКА</a></li>
        <li><a href="#" @click="infomag('ТОПОЛЯНСКАЯ')">ТОПОЛЯ</a></li>
        <li><a href="#">БАСЫ</a>
        <ul class="submenu">
          <li><a href="#" style="color:blue" @click="infomag('БАСЫ ОСНОВНОЙ')">РАБОЧИЙ</a></li>
          <li><a href="#" style="color:red" @click="infomag('БАСЫ РЕЗЕРВНЫЙ')">РЕЗЕРВНЫЙ</a></li>

        </ul>
      </li>

        <li><a href="#" class="general" style="color: darkred;">Спрятать все</a></li>

      </ul>
    </li>


    <li><a href="#" class="active">Справочники<span class="fa fa-angle-down"></span></a>
      <ul class="submenu">
        <li><a href="#" class="general"  @click="$router.push('/editopora')">Редактор базы электро и транспортных опор</a></li>
        <li><a href="#" class="general">Редактор магистральных делителей</a></li>
        <li><a href="#" class="general" @click="$router.push('/editmag')">Редактор магистралей на карте</a></li>
        <li style="background-color: red;"><a href="#" class="general" @click="tabl">Удаленные абоненты</a></li>
       
       

      </ul>
    </li>


  </ul>
</nav>
  </div>
 
<!--БЛОК ДОПОЛНИТЕЛЬНЫЕ ПАНЕЛИ-->



<!--Вызов блока поиска абонентов на делителях-->

<search-map  @search-data="result" v-show="isSearch"></search-map>

<!------------------------------------------------->

<!--Список всех делителей-->

<div class="list_del" v-show="isList">
    
   
    <table id="ses_table">
  
      <thead>
        <tr>
  <th>№ делителя</th>
  <th>Тип</th>
  <th>Адрес делителя</th>
  <th>Порт</th>
  <th>Иполт</th>
  <th >Широта</th>
  <th>Долгота</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="`employee-${index}`">
           <td style="cursor:pointer" @click="getnumber(row.number_del,row.ipolt)">{{ row.number_del}} </td>
           <td>{{ row.number_def}} </td>
           <td>{{ row.address_del}} </td>
           <td>{{ row.port}} </td>
           <td>{{ row.ipolt}} </td>
           <td>{{ row.latitude}} </td>
           <td>{{ row.longitude}} </td>

   
        </tr>
      </tbody>
    </table>

   
  <div class="button_block">
  
  <button type="button" class="btn btn-danger" @click="exit_list">ВЫХОД</button>
  
  </div>
 </div>

<div class="inform" v-show="isVisible">
    
    <!--Вывод таблички списка клиентов-->
   
   <div class="content">
   
    <span v-html="delit_data"></span>
   
   </div>
   
   <photo-del class="photo" v-show="isPhoto"></photo-del>
   
   <!--Вывод данных по делителю-->
   
       <div class="card inf">
         
         <div class="card-body">
           <h5 class="card-title">ДАННЫЕ ПО ДЕЛИТЕЛЮ</h5>
           <p class="card-text">
             <ul v-for="item of del_info" :key="item.id">
               <li><b>№ делителя: </b> {{item.number_del}}</li>
               <li><b>Адрес делителя:</b> {{item.address_del}}</li>
                <li><b>ИП: </b>{{item.ipolt}}</li>
                <li><b>Порт коммутатора: </b>{{item.port}}</li>
                <li><b>Координаты делителя: </b> <b class="cor">С.ш.</b> {{item.latitude}} <b class="cor">В.д.</b> {{item.longitude}}    </li>
                <li><b>Количество портов: </b><b style="color:darkblue">{{item.number_def}}</b></li>
                <li><b>Занято портов: </b><b style="color:darkred">{{delstat.length}}</b></li>
                <li><b>Осталось свободных портов: </b><b style="color:darkgreen">{{item.number_def-delstat.length}}</b></li>
       
             </ul>
       
           </p>
           <button class="btn"  :class="[isPlus ? 'btn-dark' : 'btn-primary']"  @click="plus()">ДОБАВИТЬ НОВОГО АБОНЕНТА</button>
           <button class="btn btn-success" @click="delet_abonent"  >УДАЛИТЬ АБОНЕНТА С ДЕЛИТЕЛЯ</button>
           <button class="btn btn-danger" @click="exit_del">ВЫХОД</button>
       
         </div>
       </div>
   
       <div class="alert alert-success" role="alert" v-show="al">
     <h4 class="alert-heading" style="color:red;text-shadow:1px 1px 1px darkred">ВНИМАНИЕ!!!</h4>
     <p>На этом делителе все порта заполнены</p>
     <hr>
     <p class="mb-0"><button class="btn btn-success" @click="al=false">OK</button></p>
   </div>
   </div>



<!--Шаблон добавления нового абонента на делитель-->


<div class="card addabonent" style="width: 42rem;" v-show="isAddabonent">

<div class="card-body">
  <h4 class="card-title" style="text-align: center;">Добавление нового абонента на делитель</h4>
  
  <p>
    <ul>

      <h5 class="card-title" style="text-align: center;">Поиск нового абонента </h5>

<form class="d-flex" role="search" @submit.prevent>           
         
         <input class="form-control me-2" type="search" placeholder="Поиск" aria-label="Search" @keyup='loadSuggestions' v-model='searchText'>
         <button class="btn btn-outline-success" @click="abonent">ПОИСК</button>

          <div class='suggestionlist' v-if="suggestiondata.length" >
 
           <ul > 
             <li v-for= '(item,index) in suggestiondata' @click='itemSelected(index)' :key="item.id" > 
 {{ item.realname }} 
             </li> 
           </ul>
         </div> 
 
 
 
       </form>
</ul>


  </p>
  <p class="card-text"></p>

    <div class="new_abon" v-show="isNewAbon">
  <div class="card" style="width: 32rem;">
  <div class="card-header" style="text-align: center; background-color: blue;color: white;">
    Данные нового абонента
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item"><b>Абонент: </b> {{ newname }} </li>
    <li class="list-group-item"><b>Адрес: </b> {{ newstreet }} {{ newdom }}</li>
    <li class="list-group-item"><b>ИП: </b>{{ newip }}</li>
    <li class="list-group-item"><b>МАК: </b> {{ newmac }} </li>
  </ul>
</div>

<button class="btn btn-info" @click="insert_newabon">ВСТАВИТЬ НА ДЕЛИТЕЛЬ</button>
<button class="btn btn-danger" @click="isAddabonent=false">ОТМЕНА</button>

</div>



  

</div>
</div>









<!---_________________________________-->


<!--Панель удаления абонента с делителя-->

<div class="delit_abon"  v-show="isdelAbon">
<div class="card" >
  <div class="card-header">
    УДАЛЕНИЕ АБОНЕНТА С ДЕЛИТЕЛЯ
  </div>
  <div class="card-body">
    <h5 class="card-title">Список абонентов на делителе</h5>

    <table>
  
  <tr>
    <th>№ </th>
    <th>Абонент</th>
     <th>Адрес</th>
    <th>Ип</th>
    <th>Мак</th>
    <th>Действие</th>
  </tr>
 
  <tr v-for="(itm,dex) of delet" :key="itm.id">
    <td>{{ dex+1 }}</td>
    <td>{{itm.realname}}</td>
    <td>{{itm.streetname}} {{ itm.buildnum }}</td>
    <td>{{itm.ip}}</td>
    <td>{{itm.mac}}</td>
    <td style="background-color: white;"><button style="background-color: darkblue;color:white;"  data-bs-toggle="modal" data-bs-target="#exampleModal"  @click="delone(itm.realname,itm.streetname,itm.buildnum,itm.login,itm.ip,itm.Cash,itm.mac)">УДАЛИТЬ</button></td>
  
    </tr>

</table>





    <p class="card-text">
      <ul>
      <li><b>Адрес делителя:</b> {{address_del}}</li>
                <li><b>ИП: </b>{{ipolt}}</li>
                <li><b>Порт коммутатора: </b>{{port}}</li>
                <li><b>Количество портов: </b><b style="color:darkblue">{{number_def}}</b></li>
               
            
         </ul>       
       
    </p>
    <button class="btn btn-info" @click="tabl" >БАЗА УДАЛЕННЫХ АБОНЕНТОВ</button>
    <button class="btn btn-danger" @click="isdelAbon=false">ЗАКРЫТЬ</button>
  </div>
</div>

</div>



<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Внимание!!! Этот абонент будет удален</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ul>
          <li> {{ delname }}</li>
          <li> {{ delstreet }} {{ deldom }}</li>   
          <li><b>Причина удаления: </b><input style="width:400px" type="search" v-model="causa">  </li>           
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"  @click="insert_delete_abon">СОГЛАСЕН</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">НЕТ</button>
        
      </div>
    </div>
  </div>
</div>



<!---->

<tables-deleting-vue v-show="isTables"></tables-deleting-vue>

<!--Блок карта--------------------------------->  


<!--Вставка нового делителя-->

<insert-new-del :kord=coords  class="newdel" v-show="isND" @closend="closend"></insert-new-del>

<!----------------------------->




<yandex-map
    
        :settings="settings"
        :coords="coords"
        :zoom="14"
        style="width: 100%; height: 800px;"
        @click="onClick"
        
        >


<!--Блок вывода делителей-->


<div v-for="del in delit" :key="del.id" >

<!-- Офис первый порт делитель на 8-->  

<div v-if="del.number_def==8 & del.port==1 & del.ipolt=='10.79.254.246'">
    
    <ymap-marker 
      
      
      :marker-id='del.id' 
      :coords='[del.latitude, del.longitude]'
      :icon='bar8'
      :hint-content="del.address_del"
      @click="oleg(del.number_del,del.ipolt)" 
      
      
    />
      </div>

<!-- Офис второй порт делитель на 8-->  

      <div v-if="del.number_def==8 & del.port==2 & del.ipolt=='10.79.254.246'">
    
    <ymap-marker 
      
      
      :marker-id='del.id' 
      :coords='[del.latitude, del.longitude]'
      :icon='bar82'
      :hint-content="del.address_del"
      @click="oleg(del.number_del,del.ipolt)" 
      
      
    />
      </div>



<!-- Барановка первый порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==1 & del.ipolt=='10.79.254.245'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bar8'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"

          
          
        />
          </div>

<!-- Барановка первый порт делитель на 4-->  
    
     <div v-if="del.number_def==4 & del.port==1 & del.ipolt=='10.79.254.245'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bar4'
          :hint-content="del.address_del"
         @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>
    
      <!-- Барановка второй порт делитель на 8-->  

    <div v-if="del.number_def==8 & del.port==2 & del.ipolt=='10.79.254.245'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bar82'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>
    
<!-- Барановка второй порт делитель на 16-->  


   <div v-if="del.number_def==16 & del.port==2 & del.ipolt=='10.79.254.245'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bar8216'
          :hint-content="del.address_del"
         @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
     </div>

    <!-- Барановка третий порт делитель на 8-->  
         

    <div v-if="del.number_def==8 & del.port==3 & del.ipolt=='10.79.254.245'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bar83'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
        />
          </div>
    
      <!-- Барановка третий порт делитель на 4-->  


 <div v-if="del.number_def==4 & del.port==3 & del.ipolt=='10.79.254.245'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bar43'
          :hint-content="del.address_del"
         @click="oleg(del.number_del,del.ipolt)"
          
        />
          </div>

<!-- Тополянская 240 первый порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==1 & del.ipolt=='10.79.254.240'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top248'
        
          :hint-content="del.address_del"
        @click="oleg(del.number_del,del.ipolt)"
          
            
        />
          </div>




<!-- Тополянская 240 первый порт делитель на 2-->  

<div v-if="del.number_def==2 & del.port==1 & del.ipolt=='10.79.254.240'">
    
    <ymap-marker 
      
      
      :marker-id='del.id' 
      :coords='[del.latitude, del.longitude]'
      :icon='top2402'
    
      :hint-content="del.address_del"
    @click="oleg(del.number_del,del.ipolt)"
      
        
    />
      </div>










<!-- Тополянская 240 второй порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==2 & del.ipolt=='10.79.254.240'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top2428'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
     
        />
          </div>

<!-- Тополянская 240 второй порт делитель на 16-->  

 <div v-if="del.number_def==16 & del.port==2 & del.ipolt=='10.79.254.240'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top24216'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>

<!-- Тополянская 240 второй порт делитель на 4-->  

 <div v-if="del.number_def==4 & del.port==2 & del.ipolt=='10.79.254.240'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top2424'
          :hint-content="del.address_del"
         @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>

<!-- Тополянская 240 третий порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==3 & del.ipolt=='10.79.254.240'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top2438'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>          

<!-- Тополянская 240 третий порт делитель на 4-->  

 <div v-if="del.number_def==4 & del.port==3 & del.ipolt=='10.79.254.240'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top2434'
          :hint-content="del.address_del"
         @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>  



<!-- Тополянская 240 четвертый порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==4 & del.ipolt=='10.79.254.240'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top2448'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>  


<!-- Тополянская 240 четвертый порт делитель на 4-->  

 <div v-if="del.number_def==16 & del.port==4 & del.ipolt=='10.79.254.240'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top2416'
          :hint-content="del.address_del"
         @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>  



<!-- Тополянская 244 первый порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==1 & del.ipolt=='10.79.254.244'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top244183'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div> 
          

          <div v-if="del.number_def==4 & del.port==1 & del.ipolt=='10.79.254.244'">
    
    <ymap-marker 
      
      
      :marker-id='del.id' 
      :coords='[del.latitude, del.longitude]'
      :icon='top24414'
      :hint-content="del.address_del"
      @click="oleg(del.number_del,del.ipolt)"
      
     
 
    />
      </div> 
       
<!-- Тополянская 244 второй порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==2 & del.ipolt=='10.79.254.244'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top244283'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div> 
          
<!-- Тополянская 244 третий порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==3 & del.ipolt=='10.79.254.244'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='top244383'
          :hint-content="del.address_del"
         @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>            



<!-- Басы 231 первый порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==1 & del.ipolt=='10.79.254.231'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas2318'
          :hint-content="del.address_del"
         @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>    


<!-- Басы 231 первый порт делитель на 4-->  

 <div v-if="del.number_def==4 & del.port==1 & del.ipolt=='10.79.254.231'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas2314'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>    


<!-- Басы 231 второй порт делитель на 4-->  

 <div v-if="del.number_def==4 & del.port==2 & del.ipolt=='10.79.254.231'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas23124'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>   



<!-- Басы 231 второй порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==2 & del.ipolt=='10.79.254.231'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas23128'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>  
          
  
 <!-- Басы 231 третий порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==3 & del.ipolt=='10.79.254.231'">
    
    <ymap-marker 
      
      
      :marker-id='del.id' 
      :coords='[del.latitude, del.longitude]'
      :icon='bas23138'
      :hint-content="del.address_del"
      @click="oleg(del.number_del,del.ipolt)"
      
     
 
    />
      </div>          


 <!-- Басы 231 третий порт делитель на 4-->  

 <div v-if="del.number_def==4 & del.port==3 & del.ipolt=='10.79.254.231'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas23134'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>

 <!-- Басы 231 четвертый порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==4 & del.ipolt=='10.79.254.231'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas23148'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div> 
          
 <!-- Басы 231 четвертый порт делитель на 16-->  

 <div v-if="del.number_def==16 & del.port==4 & del.ipolt=='10.79.254.231'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas231416'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>  
          

<!-- Тополянская 229 первый порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==1 & del.ipolt=='10.79.254.229'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas22918'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>
          
<!-- Тополянская 229 первый порт делитель на 16-->  

 <div v-if="del.number_def==16 & del.port==1 & del.ipolt=='10.79.254.229'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas229116'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div> 


<!-- Тополянская 229 второй порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==2 & del.ipolt=='10.79.254.229'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas22928'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>
          
<!-- Тополянская 229 второй порт делитель на 16-->  

 <div v-if="del.number_def==16 & del.port==2 & del.ipolt=='10.79.254.229'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas229216'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div> 

<!-- Тополянская 229 третий порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==3 & del.ipolt=='10.79.254.229'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas22938'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>

<!-- Тополянская 229 четвертый порт делитель на 8-->  

 <div v-if="del.number_def==8 & del.port==4 & del.ipolt=='10.79.254.229'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas22948'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"
          
         
     
        />
          </div>


<!-- Тополянская 229 четвертый порт делитель на 2-->  

 <div v-if="del.number_def==2 & del.port==4 & del.ipolt=='10.79.254.229'">
    
        <ymap-marker 
          
          
          :marker-id='del.id' 
          :coords='[del.latitude, del.longitude]'
          :icon='bas22942'
          :hint-content="del.address_del"
          @click="oleg(del.number_del,del.ipolt)"     
        />

          </div>          

          </div>






<!--Конец блока вывода делителей-->


 <!-- Поиск делителя по параметрам -->
    
    
 <ymap-marker v-for="dl in search_delit" :key="dl.id" 
         
         :marker-id='dl.id' 
         :coords='[dl.latitude, dl.longitude]'
         :icon='isk'
         :hint-content="dl.address_del"
         @click="oleg(dl.number_del,dl.ipolt)"
         
        
    
       />





<!--Вывод опор на карту-->

<!--Отображать электроопору с ящиком-->


<div v-for="op in opora_s" :key="op.id" >
<div v-if="op.element=='ящик'">
<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:icon='elbox2'
:options="{preset: 'islands#nightStretchyIcon'}"
:properties="{iconContent: 'Ящик'}"
:hint-content="op.adres+ ' Номер опоры: ' +op.number"
@click="dataopora(op.number,op.adres,op.element,op.latitude,op.longitude)"

/>

</div>
</div>

<!--Отображать электроопору с муфтой-->


<div v-for="op in opora_s" :key="op.id" >
  <div v-if="op.element=='муфта'">

<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:icon='elmufta2'
:options="{preset: 'islands#violetStretchyIcon'}"
:properties="{iconContent: 'Муфта'}"
:hint-content="op.adres+ ' Номер опоры: ' +op.number"
@click="dataopora(op.number,op.adres,op.element,op.latitude,op.longitude)"

/>

</div>
</div>


<!--Отображать депоопору с ящиком-->


<div v-for="op in opr" :key="op.id" >
  <div v-if="op.element=='ящик'">
<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:icon='depobox4'
:options="{preset: 'islands#blueMassTransitIcon'}"

:hint-content="op.adres+ ' Номер опоры: ' +op.number +  op.element"

/>


</div>
</div>



<div v-for="op in opr" :key="op.id" >
<div v-if="op.element=='муфта'">
<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:options="{preset: 'islands#blueRapidTransitIcon'}"
:icon='depomufta4'
:hint-content="op.adres+ ' Номер опоры: ' +op.number + op.element"

/>

</div>
</div>



<!-- Вывод на карту магистралей -->

<div v-for="mg in magica" :key="mg.id" >
<ymap-marker

     marker-type="polyline"
     :coords="polylineGeometry"
     
     

     :hint-content="mg.name_magistral"
    
        :marker-stroke="{color: mg.color_magistral, width:4}"
      
  
     ></ymap-marker>

</div>

</yandex-map>    

</div>

</template>

<script>

const settings = {
      apiKey: 'c4928921-ad57-4083-a3e9-40a9288d9714',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1'
    }

import { yandexMap,ymapMarker } from 'vue-yandex-maps'
import axios from 'axios'
import photo from '../components/FilePhoto.vue'
import list from '../components/ListPhoto.vue'
import SearchMap from '../components/SearchMap.vue'
import TablesDeletingVue from '@/components/TablesDeleting.vue'
import InsertNewDel from '../components/InsertNewDel.vue'


export default {
    data(){
        return {
            coords: [50.911979, 34.802763],//кординаты центра карты (Сумы)
            delit:[],//массив для вывода делителей на карту
            del_info:[],//Массив информации по делителю
            delit_data:[],//Массив списка клиентов
            isVisible:false,//отображать панель данные по делителю
            delstat:[],
            rows:[],
            search_delit:[],//массив найденных делителей
            magistra:[],//массив магистралей
            opora_s:[],//массив опор
            isOpora:false,//Отображать информацию о опоре
            setinfo:'',//информация по опоре
            opora_info:[],//массив информации о опоре
            n:0,
            name_mag:'',//имя магистрали
            color_mag:'',//цвет магистрали
            host_mag:'',//хост магистрали
            port_mag:'',//порт магистрали
            type_opora:'Электроопора',
            suggestiondata:[],
            searchText:'',
            newabon:[],//массив данных нового абонента
            login:'',
            newname:'',//имя нового абонента в панели
            newstreet:'',//удица нового абонента
            newdom:'',//дом нового абонента
            newip:'',//ип нового абонента
            newmac:'',//мак нового абонента
            isNewAbon:false,//отображать панель с данными по абоненту
            isAddabonent:false,//отображать панель добавления абонентов на делитель
            polylineGeometry:[],
            isSearch:false,//показать панель поиска
            isPhoto:false,//отображать раздео фотографии
            isOrder2:true,
            magica:[],//информация о магистрали
            isAwake:false,
            //Данные делителей
            //Барановка делители 10.79.254.245

             
        bar8: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del8(bar1).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },
    
          bar82: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del8(bar2).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 
    
          bar83: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del8(bar3).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 
    
    
         bar8216: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del16(bar2).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 
    
    
          bar4: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del4(bar1).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },
  
  
          bar43: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bar4kl(3).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },
  
//-----------------------------------


//Тополя делители 10.79.254.240

  top248: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del8(040).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },


        top2402: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del2(040).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },

   top2428: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del8(041).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },

   top24216: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del16(401).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 
        
    top2424: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del240(42).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },     


 top2438: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del8(042).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 


 top2434: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del4(042).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },   
        
        
  top2448: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del8(043).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },      
        
  top2416: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del16(403).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },   

//Тополя делители 10.79.254.244


top244183: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/top1(8).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 
        
top24414: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/top44(14).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },         


top244283: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/top2(8).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 
        
top244383: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del8(443).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 
        
//-----------------------------------


//Басы делители 10.79.254.231


//1

bas2318: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del8(bas311).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 

bas2314: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del31(4).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },         

//2

bas23128: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas312(8).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },
bas23124: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas312(4).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },

//3        
        
bas23138: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas313(8).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },
        
bas23134: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas313(4).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },
        
//4

bas23148: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas314(8).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },

bas231416: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/del16(31).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },        

//Басы делители 10.79.254.229


//1

bas22918: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas291(8).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 

bas229116: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas29(16).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },
        
//2

bas22928: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas2292(8).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },
        
bas229216: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas2292(16).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },
        
//3

bas22938: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas2293(8).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 

//4

bas22948: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas2294(8).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 

bas22942: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bas2294(2).png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        },   
        
        isdelAbon:false, //панель удаления абонентов
        delet:[], // массив удаления
        dellogin:'',
        delmac_onu:'',
        delname:'',
        delstreet:'',
        deldom:'',
        delip:'',
        delmac:'',
        delcash:'',
        causa:'',
        sleonu:[],
        deladr:'',
        delnumber:'',
        delport:'',
        delipolt:'',
        isTables:false,
        isND:false,
        opr:[],
        magshow:[],//массив готовых магистралей
        polylineGeometry:[],//массив магистралей
        }
    },

    methods:{

     onClick(e) {
    
    const cord=(e.get('coords'))
    this.$emit('get-coords',cord)

    console.log('tut',cord)




},

//Отрисовка магистралей на карте

infomag(data){


  console.log(data)

  axios.get('https://billing.online.sumy.ua/control/phpfor/magif.php', {
      
      params: {template:data
        
    }
  
  })            
          .then(response => {
  
  
       //Создаем массив с координатами
  
       this.magica=Object.keys(response.data).map(key=>{
                  return {
                    id:key,
                    ...response.data[key]
                  }      
                }) 
                
            const ident = this.magica.map(a=>a.ident_magistral).toString()
          

            this.show_mag(ident)

            console.log('ident',ident)

            console.log('info magistra',this.magica)
         
  
           
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  

},

//Выборка координат для отрисовки магистралей

show_mag(ident){
              axios.get('https://billing.online.sumy.ua/control/phpfor/magsh.php', {
      
      params: {ident_magistral:ident
        
    }
  
  })            
          .then(response => {
  
       //Создаем массив с координатами
  
            this.polylineGeometry = response.data.map((coord) => [coord.latitude, coord.longitude]);  
            this.magshow=response.data
            console.log('all of magistral',this.magshow)
                    
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  
               
      },



//Отобразить депоопоры на карты

depo_opora(){

  axios.get('https://billing.online.sumy.ua/control/phpfor/sel_opora.php', {
      
      params: {type:2
        
    }
  
  })            
          .then(response => {
  
  
       //Создаем массив с координатами
  
          this.opr=response.data
          console.log(this.opr)
         
  
           
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  

},



      //Открыть раздел фотографии
manager_photo(){
  //this.isPhoto=true
  if(!this.isPhoto){this.isPhoto=true}
  else {this.isPhoto=false}
},      


//вставка информации об опоре

insert_info_opora(numb,str){
  
  axios.get('https://billing.online.sumy.ua/control/phpfor/insert_info_opora.php', {

params: {
  set:this.setinfo,// инофрмация о опоре
  number:numb,// номер опоры
  street:str // улица
  
}
})            
    .then(response => {

      this.select_info_opora(numb,str)
      this.setinfo=''
        
     
  })
  .catch(function (response) {
      //handle error
      console.log(response)
      
  });   


},


//Получение информации о опоре

select_info_opora(numb,str){

  axios.get('https://billing.online.sumy.ua/control/phpfor/select_opora_info.php', {

params: {
  street:str,
  number:numb
  
}
})            
    .then(response => {
        

this.opora_info=response.data

console.log('vivod opor',this.opora)


     
  })
  .catch(function (response) {
      //handle error
      console.log(response)
      
  });   



},





      dataopora(numb,ad,el,lat,long){
        this.isOpora=true //отображать опору
        this.num_opora=numb
        this.adr_opora=ad
        this.elem_opora=el
        this.coord_opora_lat=lat
        this.coord_opora_lg=long

        this.select_info_opora(numb,ad)
      },

      //Вывод опор на карту

opora_res(n){
  axios.get('https://billing.online.sumy.ua/control/phpfor/opora.php', {

    params: {
      region:n
      
  }
})            
        .then(response => {
            

this.opora_s=response.data

console.log('vivod opor',this.opora)


         
      })
      .catch(function (response) {
          //handle error
          console.log(response)
          
      });   

},
  


//Список все делителей

list(){


        this.isList=true

         axios.get('https://billing.online.sumy.ua/control/phpfor/list_del.php', {
  
      
    })
    .then(response=> {
        this.rows=Object.keys(response.data).map(key=>{
                  return {
                    id:key,
                    ...response.data[key]
                  }      
                }) 
                
            const tip = this.rows.map(a=>a.number_def)
            this.tip=tip.toString()

            console.log(this.rows)

     
    })
  },


//Поиск абонентов на делителях

  result(n){
    
        
    axios.get('https://billing.online.sumy.ua/control/phpfor/search_delit.php',{

 
 params: {login:n
    }
    
}

  )
  .then(response=> {
      this.search_delit=Object.keys(response.data).map(key=>{
                return {
                  id:key,
                  ...response.data[key]
                }      
              }) 

              }) 
                  
  },    






//Количество записей на делитиле    

static_del(){

axios.get('https://billing.online.sumy.ua/control/phpfor/delitstatik.php', {
  
  params: {
    number:this.number_del
}
})            
      .then(response => {
          
   this.delstat=Object.keys(response.data).map(key=>{
            return {
              id:key,
              ...response.data[key]
            }
          })
          

  //Количество клиентов на делителе



 this.final = (this.number_def-this.delstat.length)



       
    })
    .catch(function (response) {
        //handle error
        console.log(response)
        
    })   


},




        //Вывод делителей на карту

        //Отрисовка клиентских делителей по регионам 

      del_hosts(host,port){     

axios.get('https://billing.online.sumy.ua/control/phpfor/delit_hosts.php', {

 params: {host:host,port:port

}
})            
     .then(response => {
         
  this.delit=Object.keys(response.data).map(key=>{
           return {
             id:key,
             ...response.data[key]
           }
         })         
      
   })
   .catch(function (response) {
       //handle error
       
       
   });

},

//Отрисовка всех клиентских делителей
      
del(off){


    
axios.get('https://billing.online.sumy.ua/control/phpfor/delit.php', {

      params: { off:off
      
    }
  })            
          .then(response => {
              
       this.delit=Object.keys(response.data).map(key=>{
                return {
                  id:key,
                  ...response.data[key]
                }
              })         
   
        
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        });
         
      },

//убрать все делители из карты

close_del(){
    this.delit=''
},

//Выборка данных (список) пользователей клиентских делителей из базы 

oleg(tre,ip){

this.isVisible=true
         
     axios.get('https://billing.online.sumy.ua/control/phpfor/del_bdcom.php', {

params: {
 number:tre,
 host:ip,
 
}
})            
   .then(response => {
       

this.delit_data=response.data

//console.log('eto',this.delit_data.length)


console.log('error',this.delit_data)

    
 })
 .catch(function (response) {
     //handle error
     console.log(response)
     
 });   

  //Запрос из базы для выборки параметров делителя (номер,адрес ит.д)

//Запрос из базы для выборки параметров делителя (номер,адрес ит.д)

axios.get('https://billing.online.sumy.ua/control/phpfor/del_info.php', {

params: {
 number:tre
}
})            
   .then(response => {
       
this.del_info=Object.keys(response.data).map(key=>{
         return {
           id:key,
           ...response.data[key]
         }
       })
       
 
       
//Хост делителя             

const hos=this.del_info.map(ip=>ip.ipolt)

this.ipolt = hos.toString()

//Адрес делителя
const adresonu = this.del_info.map(a=>a.address_del)

this.address_del = adresonu.toString()

//Номер делителя

const number_del = this.del_info.map(b=>b.number_del)

this.number_del=number_del.toString()

//Тип делителя

const number_def = this.del_info.map(c=>c.number_def)

this.number_def = number_def.toString()

console.log(number_def)

//Порт делителя

const port = this.del_info.map(d=>d.port)

this.port = port.toString()

    
 })
 .catch(function (response) {
     //handle error
     console.log(response)
     
 })   

},

//закрыть таблицу информации

exit_del(){
        this.isVisible=false
        this.delit_data=''

      },


exit_list(){
    this.isList=false
    this.rows=''
},

//Отобразить

show_magistral(idt){


  console.log('idt',idt)

  axios.get('https://billing.online.sumy.ua/control/phpfor/show_magistral.php', {

params: {ident_magistral:idt

 
}
})            
   .then(response => {
       

    this.polylineGeometry = response.data.map((coord) => [coord.latitude, coord.longitude]); 
    console.log(this.polylineGeometry) 

    this.info_magistral(idt)

    
 })
 .catch(function (response) {
     //handle error
     console.log(response)
     
 });   

},

info_magistral(ident){

  axios.get('https://billing.online.sumy.ua/control/phpfor/info_mag.php', {

params: {ident_magistral:ident

 
}
})            
   .then(response => {
       

    this.magistra=Object.keys(response.data).map(key=>{
         return {
           id:key,
           ...response.data[key]
         }
       })


       console.table(this.magistra)

       const name = this.magistra.map(a=>a.name_magistral)
       const color = this.magistra.map(b=>b.color_magistral)
       const host = this.magistra.map(c=>c.host)
       const port = this.magistra.map(d=>d.port)

       console.log('name',name)
       
       this.name_mag=name // имя магистрали
       this.color_mag=color // цвет магистрали
       this.host_mag=host //хост магистрали
       this.port_mag=port //порт магистрали
    
 })
 .catch(function (response) {
     //handle error
     console.log(response)
     
 });   

},

//Включение панели для поиска

search(){
  if(!this.isSearch){this.isSearch=true}
  else {this.isSearch=false}
},

//Добавление абонента на делитель
plus(){



  if(!this.isAddabonent){this.isAddabonent=true
  this.login=''
  this.searchText=''
  this.isNewAbon=false
 
  
  }
  else {this.isAddabonent=false}
},

//Поиск абонента для добавления на делитель--

loadSuggestions(){
             var el = this;
             this.suggestiondata = [];
    
             if(this.searchText != ''){
    
                axios.get('https://billing.online.sumy.ua/control/phpfor/newabonent.php', {
                   params: {
                      search: this.searchText
                   }
                })
                .then(function (response) {
                    
                    el.suggestiondata = response.data;
                });
    
             }
    
          },
          itemSelected: function(index){
             var name = this.suggestiondata[index].realname;
             var login = this.suggestiondata[index].login;
             this.login=login
    
    
             this.searchText = name;
             this.suggestiondata = [];
    
    
          
    
       },

      abonent(){
        
        this.isNewAbon=true

        


        axios.get('https://billing.online.sumy.ua/control/phpfor/select_newabon.php', {
  
  params: {
    login:this.login
}
})            
      .then(response => {
          
   this.newabon=Object.keys(response.data).map(key=>{
            return {
              id:key,
              ...response.data[key]
            }
          })
          

  //Выбор данных для панели нового абонента        

 const name = this.newabon.map(a=>a.realname)
 this.newname=name.toString()
 const street = this.newabon.map(b=>b.streetname)
 this.newstreet = street.toString()
 const dom = this.newabon.map(c=>c.buildnum)
 this.newdom = dom.toString()
 const ip = this.newabon.map(d=>d.ip)
 this.newip=ip.toString()
 const mac = this.newabon.map(e=>e.mac)
 this.newmac=mac.toString()


       
    })
    .catch(function (response) {
        //handle error
        console.log(response)
        
    })   


       },

// вставка абонента на делитель

insert_newabon(){

  axios.get('https://billing.online.sumy.ua/control/phpfor/insert_delit.php', {

params: {
  
  login:this.login, // логин абонента
  adres:this.address_del, // адрес делителя
  number:this.number_del, // номер делителя
  ipolt:this.ipolt, // ип коммутатора
  port:this.port, // порт коммутатора
  number_def:this.number_def // количество портов на делителе

 
}
})            
   .then(response => {


    alert('Вставка абонента выполнена !')

    this.isAddabonent=false

    this.oleg(this.number_del,this.ipolt)


    
    
 })
 .catch(function (response) {
     //handle error
     console.log(response)
     
 });   




},

delet_abonent(){
  if(!this.isdelAbon){this.isdelAbon=true
  
  this.select_del()

  } else {this.isdelAbon=false}
},

select_del(){



  axios.get('https://billing.online.sumy.ua/control/phpfor/deleterec.php', {

params: {del:this.number_del

 
}
})            
   .then(response => {
    this.delet=response.data 
    
 })
 .catch(function (response) {
     //handle error
     console.log(response)
     
 });   



},

delone(name,street,dom,login,ip,cash,mac) {
  this.delname=name // имя удаляемого абонента
  this.delstreet=street
  this.deldom=dom
  this.dellogin=login
  this.delip=ip
  this.delcash=cash
  this.delmac=mac


//Выборка данных об ону и делителях

axios.get('https://billing.online.sumy.ua/control/phpfor/onu_select.php', {

params: {login:this.dellogin,// логин абонента
 

 
}
})            
   .then(response => {

    this.sleonu=Object.keys(response.data).map(key=>{
            return {
              id:key,
              ...response.data[key]
            }
          })


     const nonu = this.sleonu.map(a=>a.number)
     
     this.delnumber=nonu.toString() // номер делителя

     const adr = this.sleonu.map(b=>b.addressonu)

     this.deladr=adr.toString() // адрес делителя

     const port = this.sleonu.map(c=>c.port)

     this.delport=port.toString() // порт коммутатора

     const ipolt = this.sleonu.map(d=>d.ipolt)

     this.delipolt = ipolt.toString() // ип коммутатора

     const onumac = this.sleonu.map(e=>e.mac)

     this.delmac_onu = onumac.toString() // мак оптического модема
  
    
 })
 .catch(function (response) {
     //handle error
     console.log(response)
     
 });   


  },


    

//Запись о удалении абонента и удаление абонента


insert_delete_abon(){

  if (this.causa=='') {alert('Укажите причину удаления')}
  if (this.causa!=''){


  axios.get('https://billing.online.sumy.ua/control/phpfor/info_delete_insert.php', {

params: {login:this.dellogin,// логин абонента
  realname:this.delname,// имя абонента
  streetname:this.delstreet,//улица проживания
  buildnum:this.deldom,//дом проживания
  ip:this.delip,//ип абонента
  mac_router:this.delmac,// мас роутера абонента
  cash:this.delcash, //остаток абонента
  causa:this.causa, //причина удаления
  number:this.delnumber, // номер делителя
  adres: this.deladr, // адрес делителя
  port:this.delport, //порт делителя
  ipolt: this.delipolt, // ип коммутатора
  mac_onu: this.delmac_onu // мак онушки
 
}
})            
   .then(response => {
   this.causa=''
   this.delet_abonent()
    
 })
 .catch(function (response) {
     //handle error
     console.log(response)
     
 });   


  }


},

// Функция удаления абонента на делителе


delete_abonent() {

  axios.get('https://billing.online.sumy.ua/control/phpfor/delete_abonent.php', {

params: {login:this.dellogin

 
}
})            
   .then(response => {

    alert('Удаление выполнено !!!')
    
  
    
 })
 .catch(function (response) {
     //handle error
     console.log(response)
     
 });   

},



tabl(){
  if(!this.isTables){this.isTables=true} else {this.isTables=false}
},

insertnew(){
  if(!this.isND){this.isND=true} else {this.isND=false}
}




    },

    beforeMount(){

const fio=localStorage.getItem('fio')

if(fio==''){
  this.isOrder2=false
  this.isAwake=true
}

    },


    updated(){
        this.static_del(this.number_del)  
    },
    components: { yandexMap,ymapMarker,photo,list,SearchMap,TablesDeletingVue,InsertNewDel}
}
</script>

<style scoped>
.inform {
 
 position:absolute;
 z-index: 3;

 margin-top: 1%;
 margin-left: 1%;

}

.content {
background-color: white;
box-shadow: 1px 1px 1px black;
}

.inf {
  margin-top: 1%;
}

.btn {
  margin-left: 1%;
  text-shadow: 1px 1px 1px black;
  box-shadow: 1px 1px 1px black;
}


table {
    
    margin-top: 10px;
    border-collapse: collapse;
    background-color:#e2ecfb;
    text-align: center;
    width: 70%;
  }
  tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 370px;
  }
 thead tr {
    display: block;
  }
 thead {
   
    color: #fff;
    text-align: center;     
    background: #00020a;
    text-shadow: 1px 1px 1px black;

  
  }
  th,
  td {
    padding: 5px;
    text-align:center;
    width: 800px;
    border: 1px solid #020411;
  }


  .list_del{
      position: absolute;
      z-index: 3;
      margin-top: 7%;
      margin-left: 2%;
    }

    .info_opora{
  position: absolute;
  z-index: 2;
  border: 1px rgba(105, 102, 102, 0.089) solid;
  margin-left: 3%;
  margin-top: 3%;
  background-color:white;
  border-radius: 6px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
  opacity: 0.9;  
}


@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&subset=latin,cyrillic);
*{box-sizing: border-box;}
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}
nav {background: #2809b3;
margin-top:0.5%;
margin-bottom: 1%;}
nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
nav ul:after {
  content: "";
  display: table;
  clear: both;
}
nav a {
  text-decoration: none;
  display: block;
  transition: .3s linear;
}
.topmenu > li {
  float: left;
  position: relative;
  border-left: 1px solid black;
}
.topmenu > li:first-child {border-left: 0;}
.topmenu > li > a {  
  padding: 20px 30px;
  font-size: 12px;
  text-transform: uppercase;
  color: #FEFDFD;
  letter-spacing: 2px;
  font-weight: bold;
  text-shadow: 1px 1px 1px black;
}
.topmenu > li > a.active, 
.submenu a:hover {color: #f0e9eb;}
.topmenu .fa, 
.submenu .fa {
  margin-left: 5px;
  color: inherit;
}
.submenu {
  position: absolute;
  z-index: 5;
  min-width: 200px;
  background: white;
  border-top: 1px solid #CBCBCC;
  border-left: 1px solid #CBCBCC;
  border-right: 1px solid #CBCBCC;
  visibility: hidden;
  opacity: 0; 
  transform-origin: 0% 0%;
  transform: rotateX(-90deg);
  transition: .3s linear; 
  color:#2809b3; 
}
.submenu li {position: relative;}
.submenu li a {
  color: #282828;
  padding: 10px 20px;
  font-size: 13px;
 
  border-bottom: 1px solid #CBCBCC;
}
.submenu .submenu {
  position: absolute;
  left: 100%;
  top: -1px;
  transition: .3s linear;
}
nav li:hover > .submenu {
  transform: rotateX(0deg);
  visibility: visible;
  opacity: 1;
}

.head {
  text-align: center;
  font-weight: bold;
  background-color: darkkhaki;
}

.general {
  text-align: center;
  color: #282828;
  font-weight: bolder;
}

.addabonent {
  position: absolute;
  z-index: 3;
  margin-left: 32%;
  margin-top: 5%;
  background-color: rgb(238, 234, 186);
  box-shadow: 1px 1px 1px black;
}


.autocompleteel,.autocompleteel .suggestionlist{
       width: 300px;
    }
    
    .autocompleteel input[type=text]{
       width: 100%;
       padding: 5px;
    }
    
    .suggestionlist{
       position: absolute;
       z-index: 3;
       margin-top: 7%;
    }
    
    .suggestionlist ul{
       width: 100%;
       background: whitesmoke;
       list-style: none;
       margin: 0;
       padding: 5px;
    }
    
    .suggestionlist ul li{
       background: white;
       padding: 4px;
       margin-bottom: 1px;
    }
    
    .suggestionlist li:not(:last-child){
       border-bottom: 1px solid #cecece;
    }
    
    .suggestionlist ul li:hover{
       cursor: pointer;
       background: whitesmoke;
    }

    .btn {
      margin-top: 1%;
    }

    .delit_abon {
      position: absolute;
      z-index: 3;
      margin-top: 2%;
      margin-left: 5%;
      width: 90%;
      margin-right: 5%;
    }

    h3{
  text-align: center;
  margin-top: 10%;
}



</style>