<template>

<h2 >Всего выбрано: {{ vars.length }}</h2>

<div class="mytable" v-show="isTable">
<table  >
  <thead>
  <tr>
    <th>
      Порядковый номер
    </th>
    <th>Абонент</th>
    <th>idTelegram</th>
    <th>Адрес</th>
    <th>Ip</th>
    <th>Денежный остаток</th>
  </tr>
  </thead>
  <tbody>
  <tr v-for="(item,index) of vars" :key="item.id">
    <td>{{ index+1 }}</td>
      <td>{{item.realname}}</td>
      <td>{{item.id_telegram}}</td>
      <td>{{item.streetname}}{{ ',' }}  {{item.buildnum }}</td>
      <td>{{item.ip}}</td>
      <td>{{item.Cash}} грн</td>
  </tr>
 </tbody>
</table>


</div>

<div class="butv">

  <button @click="selec" class="vbr">СДЕЛАТЬ ВЫБОР</button>

</div>


</template>

<script>

import axios from 'axios';


export default {
  props:['in'],


    data(){

        return {
          
            vars:[], //массив выборки телеграм абонентов
            g100:186, // GEPON-100 
            g300:248, // GEPON-300 
            gst:310, //Тариф статика,
            isTable:false
        }
    },
    methods:{
     
        selec(){

          this.isTable=true

            axios.get('https://billing.online.sumy.ua/control/phpfor/select_telegram.php', {
        
        params: {sort:1,variant:this.in,
          g100:this.g100, // GEPON100
          g300:this.g300,//GEPON300
          gst:this.gst //Статический тариф
       }
       })            
            .then(response => {
                
           this.vars=response.data

           console.log(this.vars)

           console.log(this.in)

        this.$emit('open-ter',this.vars)
           
         
             
          })
          .catch(function (response) {
              //handle error
              console.log(response)
              
          });







        }
    }

}
</script>

<style scoped>

.mytable {
  position: absolute;
  z-index: 2;
  margin-left: 50%;
  margin-top: 3%;
  height: 400px;
  overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
 }
 
 .mytable::-webkit-scrollbar {
      width: 0;
      height: 0;
}

 table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

thead {
  position: sticky;
  top: 0;
 
}

th {
  background-color:rgb(1, 1, 8);
  color: white;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

td, th {
  padding: 8px;
  border: 1px solid #ddd;
 
}

td {
  background-color:white;
}

h2 {
  margin-left: 100px;
  color:rgb(31, 243, 119);
  text-shadow: 4px 1px 1px black;
  font-weight: bold;
}

.vbr {
  margin-left: 20px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 20px;
  box-shadow: 1px 1px 1px black;
}


</style>