<template>

 <div>

  <div class="card" style="width: 38rem;" v-show="isNe">
 
  <div class="card-body">
    <h5 class="card-title">Добавление нового делителя</h5>
    <ul>
        <li>
            <b>Коммутатор:</b> <input type="search" class="com" list="comm" v-model="host" :placeholder=fields>
            <datalist id="comm">
                <option title="Басы" value="10.79.254.229"></option>
                <option title="Басы" value="10.79.254.231"></option>
                <option title="Тополянская" value="10.79.254.240"></option>
                <option title="Тополянская" value="10.79.254.244"></option>
                <option title="Барановка" value="10.79.254.245"></option>
                <option title="Офис" value="10.79.254.246"></option>

            </datalist>
        </li>
        <li>
            <b>Порт коммутатора:</b> <input type="search" class="port" list="ports" v-model="prt" :placeholder=fields>
            <datalist id="ports">
                <option value="Первый порт"></option>
                <option value="Второй порт"></option>
                <option value="Третий порт"></option>
                <option value="Четвертый порт"></option>
            </datalist>
        </li>
        <li>
            <b>Тип делителя:</b> <input type="search" class="tip" list="type" v-model="del" :placeholder=fields>
            <datalist id="type">
                <option value="Делитель на два"></option>
                <option value="Делитель на четыре"></option>
                <option value="Делитель на восемь"></option>
                <option value="Делитель на шестнадцать"></option>
            
            </datalist>
        </li>
        <li>
            <b>Адрес делителя:</b> <input type="search" class="address" v-model="adres" @click="select" :placeholder=fields >         
        </li>
        <li>
            <b title="Вычисляется автоматически">Номер делителя:</b> <input type="search" v-model="tdel"  >         
        </li>

        <p><b>КООРДИНАТЫ ДЕЛИТЕЛЯ</b></p>

        <ul>
            <li><b>ШИРОТА: </b><input style="width:100px;background-color: aquamarine; text-align: center;
            font-weight: bold ; " v-model="lat"/> <b>ДОЛГОТА: </b><input style="width:100px;background-color: cornsilk; text-align: center; font-weight: bolder;" v-model="long"/><button class="btn btn-success" @click="ser">ОПРЕДЕЛИТЬ</button></li>
        </ul>

    </ul>
   
    <button class="btn btn-info" @click="create">СОЗДАТЬ</button>

  </div>
</div>



<div class="card" style="width: 28rem;" v-show="par">

  <div class="card-body">
    <h4 class="card-title" style="font-weight: bolder;">Вы выбрали такие параметры: </h4>
    <p class="card-text" style="color:darkblue;font-weight:bold"><b>Коммутатор:</b>{{host}}</p>
    <p class="card-text"><b>Порт коммутатора:</b>{{prt}}</p>
    <p class="card-text" style="color:darkblue;font-weight:bold"><b>Тип делителя:</b>{{del}}</p>
    <p class="card-text"><b>Адрес делителя:</b>{{adres}}</p>
    <p class="card-text" style="color:darkblue;font-weight:bold"><b>Номер делителя:</b>{{tdel}}</p>
    <p><b style="color:crimson;font-weight:bold">КООРДИНАТЫ ДЕЛИТЕЛЯ</b></p>
    <b>ШИРОТА: </b> {{lat}} 
    <b>ДОЛГОТА: </b> {{long}}
    <p></p>   
    <button class="btn btn-dark" @click="insert_del">ВСЕ ВЕРНО</button>
    <button class="btn btn-danger" @click="exit_all">ВЫХОД</button>
  </div>
</div>


</div>

</template>

<script>

import axios from 'axios'
  
export default {
  
props: ['kord'],

    data(){


        return {
            isNe:true,
            host:'',
            del:'',
            delit:0,
            prt:'',
            port:0,
            adres:'',
            seldel:[],
            lastdel:'',
            tdel:'',
            lat:'',
            long:'',
            fields:'',
            par:false,
            newdel:[]

        }
    },
    methods:{

//Создание нового делителя

        create(){

//Проверяем заполненость полей

if(this.host=='') {this.fields='ПОЛЕ НЕ ЗАПОЛНЕНО' }

if(this.prt=='') {this.fields='ПОЛЕ НЕ ЗАПОЛНЕНО'}

if(this.del=='') {this.fields='ПОЛЕ НЕ ЗАПОЛНЕНО'}

if(this.adres=='') {this.fields='ПОЛЕ НЕ ЗАПОЛНЕНО'}

if(this.lat=='') {alert('Заполните поле координаты делителя')}

if(this.long=='') {alert('Заполните поле координаты делителя')}

else {

    this.isNe=false
    this.par=true
}


},

//Вставка нового делителя в базу данных

insert_del(){

    if(this.del=='Делитель на два'){this.delit=2}
    if(this.del=='Делитель на четыре'){this.delit=4}
    if(this.del=='Делитель на восемь'){this.delit=8}
    if(this.del=='Делитель на шестнадцать'){this.delit=16}
    if(this.prt=='Первый порт'){this.port=1}
    if(this.prt=='Второй порт'){this.port=2}
    if(this.prt=='Третий порт'){this.port=3}
    if(this.prt=='Четвертый порт'){this.port=4}

    
    axios.get('https://billing.online.sumy.ua/control/phpfor/insnewdel.php', {

params: {host:this.host,//хост коммутатора
    port:this.port,//порт коммутатора
    type:this.delit,//тип делителя
    number:this.tdel,//номер делителя
    adr_del:this.adres,//адрес делителя
    latitude:this.lat,//широта
    longitude:this.long//долгота
}
    
})
          
     .then(response=> {
    this.newdel=Object.keys(response.data).map(key=>{
              return {
                id:key,
                ...response.data[key]
              }      
            }) 

            this.newdel=response.data

            alert('Поздравляем..Делитель создан')

     //Закрывыем окно       
            this.par=false

           
                            
      })
      .catch(function (response) {
          //handle error
          console.log(response)
          
      });  
   
},

//Выход из выбранных параметров

exit_all(){
    this.par=false
},

//Вставляем координаты(в скобочках указано число знаков после точки)

        ser(){
          
            this.lat=this.kord[0].toFixed(4)
            this.long=this.kord[1].toFixed(4)
            
        },


        exitnd(){
            this.$emit('closend')
        },

//Запрашиваем данные о последнем делителе

        select(){

            alert(this.host)
            alert(this.port)
            alert(this.delit)

            if(this.del=='Делитель на два'){this.delit=2}
            if(this.del=='Делитель на четыре'){this.delit=4}
            if(this.del=='Делитель на восемь'){this.delit=8}
            if(this.del=='Делитель на шестнадцать'){this.delit=16}

            if(this.prt=='Первый порт'){this.port=1}
            if(this.prt=='Второй порт'){this.port=2}
            if(this.prt=='Третий порт'){this.port=3}
            if(this.prt=='Четвертый порт'){this.port=4}


            axios.get('https://billing.online.sumy.ua/control/phpfor/selectdel.php', {

params: {host:this.host,port:this.port,type:this.delit
}
    
})
          
     .then(response=> {
    this.seldel=Object.keys(response.data).map(key=>{
              return {
                id:key,
                ...response.data[key]
              }      
            }) 

            this.seldel=response.data

           const lastdel=this.seldel.map(a=>a.number_del)

           this.lastdel=lastdel.toString()
           this.tdel = lastdel[lastdel.length - 1];
      
                            
      })
      .catch(function (response) {
          //handle error
          console.log(response)
          
      });  

            
        }
    },
 

}
</script>

<style lang="css" scoped>

.card {
    position: absolute;
    z-index: 4;
    margin-left: 8%;
    margin-top: 14%;
}




li{
    margin-bottom: 1%;
}

.btn {
    margin-left: 1%;
    box-shadow: 1px 1px 1px black;
    text-shadow: 1px 1px 1px black;
}

.address{
    width: 400px;
}

::placeholder {
  color: red;
  font-weight: bold;
}

.com {
    width:200px;
}

.port {
    width: 200px;
}

.tip {
    width:200px;
}


</style>