<template>
  <ul>
    <li>{{ ident }}
    <select v-model="selectedTag" >
      <option v-for="tag in tags" :key="tag.id" :value="tag" >
        {{ tag.name_magistral }}
        {{ tag.host }}
        {{ tag.port }}
        
      </option>
    </select>
  <button :class="[isPlus ? 'bind':'bindpress']" @click="bind(ident)" >{{ status }}</button>
  </li>
  </ul>
</template>

<script>

import axios from 'axios';

export default {
    props:['ident'],
  data(){
    return {
      tags: [],
      selectedTag: null,
      isPlus:true,
      status:'ПРИВЯЗАТЬ',
      template:'',


    }
  },

  mounted() {
    this.fetchTags();
  },
  methods: {

    fetchTags(){
      axios.get('https://billing.online.sumy.ua/control/phpfor/select_info_mag.php', {
      
      params: {
        
    }
  
  })            
          .then(response => {
  
  
       //Создаем массив с координатами
  
       this.tags=Object.keys(response.data).map(key=>{
            return {
              id:key,
              ...response.data[key]
            }
          })

        

          console.table(this.tags)
  
           
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  


    },

    bind(template,id){
    if(!this.isPlus){this.isPlus=true,this.status="ПРИВЯЗАТЬ",this.bind_process(0)
  
  } else {this.isPlus=false,this.status="ПРИВЯЗАН",this.template=template,this.bind_process(1)
  if (this.selectedTag) {
        const selectedTagId = this.selectedTag.id;
        // Передайте selectedTagId в нужную вам функцию
        console.log('Выбранный тег ID:', selectedTagId);
      }
}
      
    },

    bind_process(status){

      axios.get('https://billing.online.sumy.ua/control/phpfor/update_info_mag.php', {
      
      params: {status:status,template:this.template,id:this.selectedTag.id
        
    }
  
  })            
          .then(response => {
                   
           
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        }) 


    },
   
  }

}
</script>

<style scoped>

.bind {
  margin-left: 1%;
  border-radius: 6px;
  background-color: gray;
  color: white;
  text-shadow: 1px 1px 1px black;
  margin-top: 1%;
}

.bindpress {
  margin-left: 1%;
  border-radius: 6px;
  background-color:rgb(0, 255, 64);
  margin-top: 1%;
}

</style>