<template>

<div class="awake" v-show="isAwake">
  
  <h3 >Для пользование программой пожалуйста авторизируйтесь <a href="https://scada.online.sumy.ua">https://scada.online.sumy.ua</a></h3>
  
  
  </div>

<div class="order" v-show="isOrder2">
<h1>Клиентская телеграм рассылка</h1>

<div class="block">
  <div id="my-carousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button v-for="(slide, index) in slides" :key="index" type="button" :data-bs-target="'#my-carousel'" :data-bs-slide-to="index" :class="{'active': index === activeIndex}"></button>
    </div>
    <div class="carousel-inner">
      <div v-for="(slide,index) in slides" :key="index" class="carousel-item" :class="{'active': index === activeIndex}">

  <div class="tex">
      
          <h2>{{ slide.ger }}</h2>
          <TelegramVariant @open-ter="oleg"   :in="index"/>

        </div>

        <img :src="slide.imageSrc" class="d-block w-100" alt="...">
      
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#my-carousel" data-bs-slide="prev" @click="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#my-carousel" data-bs-slide="next" @click="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>




<div class="send">

  <b>ТЕКСТ СООБЩЕНИЯ: <input type="search" v-model="mess"></b>
  <button @click="sending" style="margin-left:10px;background-color: black;color: aliceblue;">ОТПРАВИТЬ СООБЩЕНИЕ</button>
 

</div>


<div class="exit">

 <button class="btn btn-success" @click="$router.push('/home')">ВЫХОД</button>

</div>


<div class="progress" v-if="showProgress">
      <div class="progress-bar" :style="{ width: progress + '%' }"></div>
      <div class="progress-text">{{ processedItems }} / {{ totalItems }}</div>
    </div>

</div>


</template>

<script>

import axios from 'axios';

import TelegramVariant from '@/components/TelegramVariant.vue';



export default {
    data(){
        return {
          mess:'',
          variant:'',
          activeIndex: 0,
          slides: [
        { imageSrc: require('../images/fon1.jpg') ,ger:'Отправить всем' },
        { imageSrc: require('../images/fon2.jpg'),ger:'Закончились деньги' },
        { imageSrc: require('../images/fon3.jpg'),ger:'Не хватает денег на оплату тарифа' },
        { imageSrc: require('../images/fon4.jpg'),ger:'Отправить выборкой' },
      ],
      showProgress: false,
      progress: 0,
      totalItems: 0,
      processedItems: 0,
      secondvars:[],
      idclient:[],
      isOrder2:true,
      isAwake:false


        }
    },

    beforeMount(){

const fio=localStorage.getItem('fio')

if(fio==''){
  this.isOrder2=false
  this.isAwake=true
}

    },

    components:{TelegramVariant},


    methods:{
      oleg(n) {
        this.secondvars=n
        this.idclient = this.secondvars.map(a=>a.id_telegram)
        //console.log('bazaid',this.idclient.toString())
      },
    
     
        sending(){
          
      this.totalItems = this.secondvars.length;

      

      let i = 0;
      const intervalId = setInterval(() => {
        this.progress = ((i + 1) / this.secondvars.length) * 100;
        this.processedItems = i + 1;

        if (i >= this.secondvars.length - 1) {
          clearInterval(intervalId);
          this.showProgress = false;
        }

        // ваш код здесь
        
        const currentIdclient = this.idclient[i];
console.log(currentIdclient)
        this.send(currentIdclient)

        i++;
      }, 1000);

      this.showProgress = true;

        },

        send(ids){

console.log(ids)

            axios.get('https://bas.online.sumy.ua/cisco/telegram.php', {
    
    params: {idmess:ids,
      message: this.mess
  }
})            
        .then(response => {
          this.mess='11111'
            
                
      })
      .catch(function (response) {
          //handle error
          console.log(response)
          
      });


        }
    

    },
  

}
</script>

<style scoped>

.block {
  width: 80%;
  margin-left: 10%;
 
}



img {
  width: 200px;
  height: 600px;

}

.tex {
  position: absolute;
  z-index: 3;
  
}

h3{

  color:black;
  text-shadow: 1px 1px 1px black;
  margin-left: 50%;
  margin-top: 2%;
  width: 100%;
}

.tit {
  text-align: center;
  
}

h2{
  color: white;
  text-shadow: 4px 1px 1px black;
  text-align: center;
  width: 100%;
}

.send {
  margin-top: 7px;
  text-align: center;
}

input {
  width: 800px;
}

h1 {
  text-align: center;
}

.exit {
  text-align: center;
}

.btn {
  margin-top: 10px;
  border-radius: 50%;
  background-color: crimson;
  font-size: 26px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.progress {
  margin-left: 10%;
  margin-top: 10px;
  width: 80%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ddd;
  padding: 0 10px;
  box-sizing: border-box;
}

.progress-bar {
  height: 100%;
  background-color: #1445cc;
  transition: width 0.3s ease-in-out;
}

.progress-text {
  font-size: 16px;
}


.awake {
  margin-right: 50%;
  margin-top: 10%;
}

</style>