<template>

<button class="updt" @click="der">ОБНОВИТЬ</button>



   <div class="pixel" v-for="image in images" :key="image.id">   
    
    <img  v-if="num_opora==image.number_opora & street_opora==image.street_opora"

    :src=image.image_path :alt="image.name" @click="al(image.image_path,image.name,image.description,image.street_opora,image.number_opora)"/>
  
 
</div>




  <div class="card" style="width: 24rem;" v-show="isOriginal" >
    <img style="width: auto;height: auto;" :src=urls class="card-img-top" alt="..." >
  <div class="card-body">
    <h5 class="card-title">{{ tit }}</h5>
    <p class="card-text">{{ ds }}</p>
    
    <a href="#" class="btn btn-danger" @click="isOriginal=false">ЗАКРЫТЬ</a>
  </div>
</div>


</template>


<script>

import axios from 'axios'

export default {
    props:['num_opora','street_opora'],
    data(){
        return {
            images:[],
            isOriginal:false,
            urls:'',
            tit:'',
            ds:'',
            st_opora:'',
            st_num:''

        }
    },
    created(){
        axios.get('https://bas.online.sumy.ua/get_images.php', {

params: {
}
})            
    .then(response => {
        this.images=Object.keys(response.data).map(key=>{
                    return {
                      id:key,
                      ...response.data[key]
                    }      
                  }) 

      
             
  })
  .catch(function (response) {
      //handle error
      
      
  });
    },
    methods:{
        al(url,title,descr,strt,nm){
            this.isOriginal=true
            this.urls=url
            this.tit=title
            this.ds=descr
            this.st_opora=strt
            this.st_num=nm

        },
        der(){
          axios.get('https://bas.online.sumy.ua/get_images.php', {

params: {
}
})            
    .then(response => {
        this.images=Object.keys(response.data).map(key=>{
                    return {
                      id:key,
                      ...response.data[key]
                    }      
                  }) 

      
             
  })
  .catch(function (response) {
      //handle error
      
      
  });
          
        }
    }

}
</script>

<style scoped>

img {
    width: 100px;
    height: 130px;
    margin-left: 2%;
    margin-bottom: 2%;
    cursor: pointer;
}


.card {
    position: absolute;
    z-index: 3;
   margin-left: 110%;
   margin-top:-40%; 
}



@media screen and (max-width: 1280px) {


  .card {
    position: absolute;
    z-index: 3;
   margin-left: 60%;
   margin-top:-40%; 
}





}







.card-img-top {
    width: 300px;
    height: 150px;
   text-align: center;
    margin-top: 1%;
    
}

.updt {
  position: absolute;
  z-index: 3;
  margin-left: 87%;
  margin-top: -17%;
  background-color: blue;
  color:white;
  text-shadow: 1px 1px 1px black;
  border-radius: 4px;
  
}

.pixel {
  display: inline-block;
  margin-left: 1%;
  margin-top: 1%;
}

</style>