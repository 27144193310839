<template>

    <div class="container-md">
    <div class="card" mx-auto>
      <form>
      <fieldset enabled>
        <legend >SCADA СИСТЕМА ИНТЕРНЕТ ПРОВАЙДЕРА</legend>
    
        <div class="mb-3">
        
        <input type="email" id="disabledTextInputMail" class="form-control" placeholder="Введите вашу почту пожалуйста" v-model="email">
        </div>
    
        <div class="mb-3">
    
          <input type="password" id="disabledTextInputPassword" class="form-control" placeholder="Введите ваш пароль пожалуйста" v-model="password">
        </div>
        <div class="mb-3">
     
        </div>
    
        <button type="button" class="btn btn-primary" @click="Registration" >ВОЙТИ</button>
    
    
    <b class="error" v-show="isVisible">{{error}}</b>
    <b class="marge" v-show="isMarge">{{marge}}</b>
    
    
      </fieldset>
    </form>
    </div>
    </div>
    </template>
    
    <script>
    
    import axios from 'axios'
    
    export default {
    
      data(){
        return {
          email:'',
          password:'',
          pass_act:'',
          role:'',
          fio:'',
          isVisible:false,
          isMarge:false,
          error:'Неверный логин или пароль',
          marge:'Заполните пожалуйста все поля',
          registr:[],
          code:'' //код генерации 
        }
      },
      methods:{
        Registration(){
    
    if (this.email==='' || this.password===''  ) {this.isMarge=true} 
    
    else {
    
         this.isMarge=false
    
    axios.get('https://billing.online.sumy.ua/control/phpfor/registr.php', {
    
          params: {
            email:this.email
        }
      }) 
    
        
      .then(response=> {
          
                 this.registr=Object.keys(response.data).map(key=>{
                    return {
                      id:key,
                      ...response.data[key]
                    }      
                  }) 
                                     
           
    
                  const passwr=this.registr.map(a=>a.password)
                  const fio=this.registr.map(b=>b.fio)
                  const role=this.registr.map(c=>c.id_role)
            
                   this.pass_act=passwr.toString()
                   this.fio=fio.toString()
                   this.role=role.toString()
    
         if (this.pass_act===this.password) {
          this.gen_code()
          this.insert_reg_info()
          localStorage.setItem('id',this.role)
          this.$router.push('/home')
          this.isVisible=false
         } 
         else if(this.pass_act!=this.password){this.isVisible=true}         
                                                
      })
         
        }
      },
      gen_code(){
        let abc = "1a2b3c4d5e6f7g8h9i0jklmnopqrstuvwxyz";
       this.code = "";
      while (this.code.length < 6) {
          this.code += abc[Math.floor(Math.random() * abc.length)];
      }

      },


      insert_reg_info(){
        axios.get('https://billing.online.sumy.ua/control/phpfor/static_registr.php', {

params: {
  role:this.role,// статус пользователя
  code:this.code,// код 
  fio:this.fio
  
  
}
})            
    .then(response => {
      localStorage.setItem('fio',this.fio)

     
        
     
  })
  .catch(function (response) {
      //handle error
      console.log(response)
      
  }); 





      }
      }
    
    }
    </script>
    
    <style lang="css" scoped>
    
    .container-md {
      padding-top: 10%;
      width: 30%;
      
    }
    
    input {
      width: 70%;
      margin-left: 18%;
      margin-right: 20%;
    }
    
    
    legend {
      text-align: center;
      margin-top: 2%;
      text-shadow: 1px 1px 1px black;
    }
    
    .btn {
      margin-left: 5%;
      margin-bottom: 2%;
      text-shadow: 1px 1px 1px black;
    }
    
    .error{
      color:red;
      margin-left: 4%;
    }
    
    .marge{
      color:red;
      margin-left: 4%;
    }
    
    </style>