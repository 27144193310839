<template>
  

<!-- Modal -->
<div class="delet" v-show="isdel">
<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">УДАЛЕНИЕ АБОНЕНТА ИЗ РАССЫЛКИ</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <b style="color: maroon;">Будет удален это абонент: </b> {{ titlename }} 
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">НЕТ</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deltel">СОГЛАСЕН</button>
      </div>
    </div>
  </div>
</div>

</div>


  <div class="card" style="width: 78rem;">

  <div class="card-body">
    <h5 class="card-title">БАЗА АБОНЕНТОВ ДЛЯ ТЕЛЕГРАМ РАССЫЛКИ</h5>
    
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item table">
        <div class="mytable">
<table  >
  <thead>
  <tr>
    <th>№</th>
    <th style="cursor:pointer;background-color: darkblue;" @click="show(1,0)">
      АБОНЕНТ 
    </th>
    <th>АДРЕС</th>
    <th>ИП</th>
    <th>ТАРИФ</th>
    <th style="cursor: pointer;background-color: darkblue;" @click="show(0,1)">ИД</th>
  </tr>
  </thead>
  <tbody>
  <tr v-for="(item,index) of telega" :key="item.id" v-on:click="selectRow(index,item.realname,item.streetname,item.buildnum,item.id_telegram,item.id,item.login)" :class="{ 'selected': index === selectedIndex }">
    <td>{{ index+1 }}</td>
      <td >{{item.realname}}</td>
      <td>{{item.streetname}} , {{item.buildnum }}</td>
      <td>{{item.ip}}</td>
      <td>{{item.Tariff}}</td>
      <td>{{item.id_telegram}}</td>
  </tr>
 </tbody>
</table>
</div>
       

    </li>
    <li class="list-group-item data" >
        <div class="row ">

          <div class="col-sm-6" v-show="isEdit">
    <div class="card">
      <div class="card-body ">
        
     <ul class="edit">
      <li>{{ titlename }}</li>
      <li>{{ titleadress }}</li>
      <li v-show="isStandart">{{ titleid }}</li>
      <li v-show="isInput"><input type="text" v-model="titleid">  <button class="btn btn-success" @click="upd" >ОБНОВИТЬ</button>    </li>
      

     </ul>
        
      </div>
    </div>
  </div>


  <div class="col-sm-6" v-show="isInsert">
    <div class="card">
      <div class="card-body datas">
        
        <ul v-for="(itm) of reale" :key="itm.id">
            <li>Абонент: {{ itm.realname }} </li>
            <li>Адрес: {{ itm.streetname }} , {{ itm.buildnum }} </li>
            <li>ИдТелеграм: <input type="search" v-model="ida"> </li>
        </ul>
        
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">ПОИСК АБОНЕНТОВ ПО ФАМИЛИИ</h5>
        
        <ul>

<form class="d-flex" role="search" @submit.prevent>           
           
           <input class="form-control me-2" type="search" placeholder="Поиск" aria-label="Search" @keyup='loadSuggestions' v-model='searchText' @click="visib">
           <button class="btn btn-outline-success" @click="result">ПОИСК</button>

            <div class='suggestionlist' v-if="suggestiondata.length" >
   
             <ul > 
               <li v-for= '(item,index) in suggestiondata' @click='itemSelected(index)' :key="item.id"  :class="{ 'selected': index === selectedIndex }" > 
   {{ item.realname }} 
               </li> 
             </ul>
           </div>    
         </form>
</ul>
     
      </div>
    </div>
  </div>
</div>
       
    </li>
    <li class="list-group-item buttons">
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
  <label class="btn btn-outline-dark" for="btnradio1"  data-bs-toggle="modal" data-bs-target="#exampleModal" @click="prov">ВСТАВИТЬ</label>

  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
  <label class="btn btn-outline-warning" for="btnradio2" @click="editor()">РЕДАКТИРОВАТЬ</label>

  <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
  <label class="btn btn-outline-primary" for="btnradio3" @click="del" data-bs-toggle="modal" data-bs-target="#exampleModal2">УДАЛИТЬ</label>

  <input type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off" >
  <label class="btn btn-outline-danger" for="btnradio4" @click="$router.push('/home')">ВЫХОД</label>


</div>
    </li>
  </ul>
  <div class="card-body">
    <h5>ВСЕГО В БАЗЕ РАССЫЛКИ: {{ telega.length }} </h5>


  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-show="isModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">БУДЕТ ОСУЩЕСТВЛЕНА ВСТАВКА ЗАПИСИ</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ul v-for="(itm) of reale" :key="itm.id">
            <li><b>Абонент: </b> {{ itm.realname }}  </li>
            <li><b>Адрес: </b> {{ itm.streetname }} , {{ itm.buildnum }} </li>
            <li><b>Идентификатор: </b>{{ ida }}</li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="noy">Нет</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="ins">Да,согласен</button>
      </div>
    </div>
  </div>
</div>





</template>

<script>

import axios from 'axios'  

export default {
    data(){
        return {
            index:0,
        searchText:'',
        suggestiondata:[],
        login:'',
        search_delit:[],
        telega:[], // массив выборки идтелеграм
        duble:[],
        reale:[],
        ida:'', //идентификатор абонента
        selectedIndex: null,
        titlename:'',//поле имя пользователя на панели
        titleadress:'',//поле адрес абонента на панели
        titleid:'',//поле ид абонента  на панели
        isInsert:false,
        isEdit:true,
        isInput:false,
        isStandart:true,
        updatelogin:'',
        updateod:'',
        isdel:false

        }
        
    },

   
    methods:{

      //Обновление данных абонента(меняем идентификатор)
      upd(){
      

          axios.get('https://billing.online.sumy.ua/control/phpfor/update_telegram_baza.php', {
      
      params: {login:this.updatelogin,
        id:this.updateod,
        newid:this.titleid
        
    }
  
  })            
          .then(response => {
  
    this.show(1,1)

    this.titleid=''


    setTimeout(  location.reload(), 1000);


          
                    
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  


      },

      //удаление абонента

      del(){
        this.isInput=false
         this.isStandart=true

        this.isdel=true

      },

      //редактирование абонента

      editor(){

        if(this.titleid=='') {alert('Выберете абонента')}
        if(this.titleid!='') {

         

         this.isInput=true
         this.isStandart=false

        }

      },
      visib(){
        this.isInsert=true
        this.isEdit=false
      },


      //Выбор абонента, выделение абонента на таблице

      selectRow(index,name,str,dom,idt,id,login) {

        this.isInsert=false  
        this.isEdit=true

        console.log('id client',idt)
        console.log('login',login)

        this.updatelogin=login // логин для обновления
        this.updateod=idt //телегам ид для обновления


        
      this.selectedIndex = index;
      

         this.titlename=name
         this.titleadress=str+dom
         this.titleid=idt

      
    },

        noy(){

            this.reale=''
            this.searchText=''
            this.show(1,0)

        },
        prov() {
            if(this.reale==0) {alert ('Выберете сначала абонента через поиск')} 

        },
        result(){
            axios.get('https://billing.online.sumy.ua/control/phpfor/telegramduble.php', {
      
      params: {login:this.login
        
    }
  
  })            
          .then(response => {
  
       // Проверка есть ли такой абонент в базе
  
            this.duble=response.data
            
            if(this.duble.length!=0) {alert ('Такой абонент уже есть в базе')}
            else {this.realselect()}
          
                    
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  
        },

        // Выборка данных по клиенту на панель (имя,адрес)

        realselect() {
            axios.get('https://billing.online.sumy.ua/control/phpfor/telegramrealname.php', {
      
      params: {login:this.login
        
    }
  
  })            
          .then(response => {
  
       // 
  
            this.reale=response.data

            console.log('select',this.reale)
            
           
          
                    
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  
        },
        


        //Отобразить таблицу с данными telegram_baza
        show(n,i){

          //this.isInput=true
          //this.isStandart=false

              axios.get('https://billing.online.sumy.ua/control/phpfor/gettelegram.php', {
      
      params: {name:n,id:i
        
    }
  
  })            
          .then(response => {
  
       //Создаем массив с координатами
  
          
            this.telega=response.data
            console.log(this.telega)
          
                    
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  
               
      },

      //Вставка записи
        ins(){

          
            

            axios.get('https://billing.online.sumy.ua/control/phpfor/insert_telegram.php', {
      
      params: {login:this.login, //логин абонента
        id_telegram:this.ida //идентификатор абонента
        
    }
  
  })            
          .then(response => {
  
 
  
 alert('Запись вставлена')
            this.reale=''
            this.searchText=''
            this.show(1,0)
            this.ida=''


          
                    
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  
           
            
        },


        loadSuggestions(){
             var el = this;
             this.suggestiondata = [];
    
             if(this.searchText != ''){
    
                axios.get('https://billing.online.sumy.ua/control/phpfor/search2.php', {
                   params: {
                      search: this.searchText
                   }
                })
                .then(function (response) {
                    
                    el.suggestiondata = response.data;
                });
    
             }
    
          },
          itemSelected: function(index){
             var name = this.suggestiondata[index].realname;
             var login = this.suggestiondata[index].login;
             this.login=login
             console.log(this.login)   
    
             this.searchText = name;
             this.suggestiondata = [];
    
    
       },

       //Удаление абонента из базы рассылки

       deltel(){
       
        axios.get('https://billing.online.sumy.ua/control/phpfor/deltet.php', {
      
      params: {login:this.updatelogin
        
    }
  
  })            
          .then(response => {
  
            this.isdel=false
            this.show(1,0)

                    
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  





       }

    },
   
    mounted(){
      this.show(1,0)
    }
}
</script>

<style scoped>

.card {
    margin-left: 3%;
    margin-top: 3%;
}

.card-title {
    text-align: center;
    font-weight: bold;
}


.mytable {

  height: 200px;
  overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
 }
 
 .mytable::-webkit-scrollbar {
      width: 0;
      height: 0;
}



 table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

thead {
  position: sticky;
  top: 0;
 
}

th {
  background-color:rgb(8, 8, 180);
  color: white;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

td, th {
  padding: 8px;
  border: 1px solid #ddd;
  
 
}

tr.selected {
  background-color:red;
  color: white;
  text-shadow: 1px 1px 1px black;
}


.buttons {
    text-align: center;
}

.card-body {
    text-align: center;
    font-weight: bolder;
    color: #01090f;
}

.datas {
    text-align: left;
    background-color: #88badb;
}

.autocompleteel,.autocompleteel .suggestionlist{
       width: 300px;
    }
    
    .autocompleteel input[type=text]{
       width: 100%;
       padding: 5px;
    }
    
    .suggestionlist{
       position: absolute;
       z-index: 3;
       margin-top: 8%;
    }
    
    .suggestionlist ul{

       width: 100%;
       background: whitesmoke;
       list-style: none;
       margin: 0;
       padding: 5px;
    }
    
    .suggestionlist ul li{
       background: white;
       padding: 4px;
       margin-bottom: 1px;
    }
    
    .suggestionlist li:not(:last-child){
       border-bottom: 1px solid #cecece;
    }
    
    .suggestionlist ul li:hover{
       cursor: pointer;
       background: whitesmoke;
    }

    .edit {
      text-align: left;
      list-style-type: none;
    }

 

</style>