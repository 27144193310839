<template>

<div class="awake" v-show="isAwake">
  
  <h3 >Для пользование программой пожалуйста авторизируйтесь <a href="https://scada.online.sumy.ua">https://scada.online.sumy.ua</a></h3>
  
  
  </div>




<div class="order"  v-show="isOrder">


  

  <div class="main" >


<div class="text-center" @click="actives" >
  <img src="../images/activ.jpg" class="rounded" alt="...">
  <h4>Активные радиус сессии</h4>
</div>
 
<div class="text-center" @click="maps">
  <img src="../images/maps.png" class="rounded" alt="...">
  <h4>Карта делителей и магистралей</h4>
</div>

<div class="text-center" @click="telegram">
  <img src="../images/telegram-bot.png" class="rounded" alt="...">
  <h4>Телеграм рассылка</h4>
</div>

<div class="text-center" @click="arch">
  <img src="../images/arch.jpg" class="rounded" alt="...">
  <h4>Архив базы биллинга</h4>
</div>
 
<div class="text-center ">
  <img src="../images/serverblock.png" class="rounded" alt="...">
  <h4>Состояние оборудования</h4>
</div>

<div class="text-center" @click="admins">
  <img src="../images/admin.png" class="rounded" alt="...">
  <h4>Модуль администратора</h4>
</div>

  </div>

<div class="title_users">
  <h4 style="margin-top: 5%;  text-align:center;color:white;border:1px solid black;margin-right: 10%;margin-left: 10%;background-color: black; border-radius: 5px;">Вы зарегистрировались как: {{ fio }} </h4>

  <button class="btn btn-danger" style="margin-left: 40%;margin-right: 40%;" @click="exit">ВЫЙТИ ИЗ ПРОГРАММЫ</button>

</div>
</div>

</template>

<script>

import axios from 'axios'

export default {
    data(){
    return{
      reg:[],
      fio:'',
      code_reg:'',
      isOrder:true,
      isAwake:false

    }},

   

    mounted(){

      this.sel_reg()

      
    },

    beforeUpdate(){

const fio=localStorage.getItem('fio')

if(fio==''){
  this.isOrder=false
  this.isAwake=true
}



    },



    methods:{
        actives(){
            this.$router.push('/activ')
        },
        maps(){
            this.$router.push('/maps')

        },
        admins(){
            this.$router.push('/admin')

        },
        telegram(){
          this.$router.push('/telegram')
        },

        arch(){
          this.$router.push('/arch')
        },

        sel_reg(){
          axios.get('https://billing.online.sumy.ua/control/phpfor/sel_reg.php', {

params: {  
}
})            
    .then(response => {
      this.reg=Object.keys(response.data).map(key=>{
                    return {
                      id:key,
                      ...response.data[key]
                    }      
                  }) 

                  const code = this.reg.map(a=>a.code_reg)
                  this.code_reg=code.toString()
                                          
                  const fio=this.reg.map(b=>b.fio)
                this.fio=fio.toString()
     
  })
  .catch(function (response) {
      //handle error
      console.log(response)
      
  }); 

        },
   exit(){

  
        axios.get('https://billing.online.sumy.ua/control/phpfor/static_registr.php', {

params: {
  role:this.role,// статус пользователя
  code:'exit',// код 
  fio:this.fio
  
  
}
})            
    .then(response => {

      localStorage.setItem('fio','')
      this.$router.push('/');
        
     
  })
  .catch(function (response) {
      //handle error
      console.log(response)
      
  }); 

   }     

    }
 

}
</script>

<style scoped>

.main{
    padding: 0;
    margin: 0;
    margin-top: 1%;
    display: flex;

    justify-content: center;
    align-items: center;
    flex-wrap: wrap;


}

img {
  width: 400px;
  height: 260px;
}

.text-center {
  min-height: 50px;
  flex: 0 3 auto;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
}

:hover.text-center {
 
  background-color: rgb(174, 193, 228);
  opacity: 0.9;

}


h3{
  text-align: center;
  margin-top: 10%;
}







</style>