<template>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">ВНИМАНИЕ !!!</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Очистить временную таблицу магистралей ?</p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="delm('all')">ОЧИСТИТЬ</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >НЕТ</button>
      </div>
    </div>
  </div>
</div>


<!--Модуль формирования записи основной таблицы магистралей-->


<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-show="isTransport">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">ФОРМИРОВАНИЕ БАЗЫ МАГИСТРАЛЕЙ</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="card-text"><b>Идентификатор магистрали: </b>{{ ident_magistral }}</p>
    <p class="card-text"><b>Имя магистрали: </b>{{ linename }}</p>
    <p class="card-text"><b>Вид магистрали: </b>{{ type_mag }}</p>
    <p class="card-text"><b>Коммутатор: </b>{{ selectedValue }}</p>
    <p class="card-text" v-show="isPort"><b>Порт коммутатора: </b>{{ portValue }}</p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#exampleModal"   @click="transport_insert" >СФОРМИРОВАТЬ</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" >ВЫЙТИ</button>
      </div>
    </div>
  </div>
</div>






<div class="mytable">
<table  >
  <thead>
  <tr>
    <th>
      ШАГ 
    </th>
    <th>ШИРОТА</th>
    <th>ДОЛГОТА</th>
  </tr>
  </thead>
  <tbody>
  <tr v-for="(item,index) of mag" :key="item.id">
    <td>{{ index+1 }}</td>
      <td>{{item.latitude}}</td>
      <td>{{item.longitude}}</td>
  </tr>
 </tbody>
</table>
</div>

<div class="editnaw">

<ul>

  <li >
  <h5>Вид магистрали</h5>
  <div class="form-check">
  <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1"  @change="type=1" @click="vibor(1)" >
  <label class="form-check-label" for="exampleRadios1">
    КЛИЕНТСКАЯ
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" @change="type=2" @click="vibor(0)">
  <label class="form-check-label" for="exampleRadios2">
    ПРИХОД
  </label>
</div>
</li>

  <li>
    <b>ЦВЕТ ЛИНИИ: <input type="color" id="linecolor" v-model='linecolor' /></b>
  </li>


<li>
  <b>Имя магистрали: <input type="search" style="margin-top: 1px;margin-bottom: 1px;"  v-model='linename' /></b>
</li>




<li v-show="isBdcom">
  <input type="text" list="bdcom" placeholder="Выберете коммутатор" v-model="selectedValue" >
  <datalist id="bdcom">
    <option value="10.79.254.229"></option>
    <option value="10.79.254.231"></option>
    <option value="10.79.254.240"></option>
    <option value="10.79.254.244"></option>
    <option value="10.79.254.245"></option>
    <option value="10.79.254.246"></option>
  
  </datalist>
</li>

<li v-show="isVibor">
  <input type="text" list="port" placeholder="Выберете порт" v-model="portValue">
  <datalist id="port">
    <option value="Первый порт"></option>
    <option value="Второй порт"></option>
    <option value="Третий порт"></option>
    <option value="Четвертый порт"></option>
  </datalist>
</li>
<li>
  <button class="btn btn-warning" @click="del_visual">ОТОБРАЗИТЬ КЛИЕНТСКИЕ ДЕЛИТЕЛИ</button>
</li>
<li>
  <button class="btn btn-info" @click="del_visual">ОТОБРАЗИТЬ МАГИСТРАЛЬНЫЕ ДЕЛИТЕЛИ</button>
</li>

<li>
  <button class="btn btn-secondary" @click="show_opora(1)">ОТОБРАЗИТЬ ЭЛЕКТРООПОРЫ</button>
</li>
<li>
  <button class="btn btn-secondary" @click="show_opora(2)">ОТОБРАЗИТЬ ОПОРЫДЕПО</button>
</li>
  <li>
    <button class="btn btn-danger" @click="delm('alone')">УДАЛИТЬ ПОСЛЕДНЮЮ ЗАПИСЬ</button>
  </li>
  <li>
    <button class="btn btn-success" @click="delm('all')">УДАЛИТЬ ВСЕ ЗАПИСИ</button>
  </li>
  <li>
    <button class="btn btn-dark" @click="show">ПОКАЗАТЬ РЕЗУЛЬТАТ</button>
  </li>
  <li>
    <button class="btn btn-dark" @click="transport" data-bs-toggle="modal" :data-bs-target=comand>СФОРМИРОВАТЬ РЕЗУЛЬТАТ</button>
  </li>
  <li>
    <button class="btn btn-danger" @click="$router.push('/home')">ВЫХОД</button>
  </li>

</ul>

</div>

     <yandex-map
      
      :settings="settings"
      :coords="coords"
      :zoom="14"
      style="width: 100%; height: 930px;"
      @click="ed"
      
      >
  <!--Прорисовка клиентских магистралей на карту-->

  <div v-if="type==1">
      <ymap-marker
     marker-type="polyline"
     :coords="polylineGeometry"
     
     :hint-content="linename"
    
        :marker-stroke="{color: linecolor, width:5}"
        :options={visible:prbas}
  
     ></ymap-marker>

     </div>


 <!--Прорисовка магистралей прихода на карту-->

 <div v-if="type==2">
      <ymap-marker
     marker-type="polyline"
     :coords="polylineGeometry"
     
     :hint-content="linename"
    
        :marker-stroke="{color: linecolor, width:9,strokeDasharray: [10, 10]}"
        :options={visible:prbas}
  
     ></ymap-marker>

     </div>


  
     <!--Прорисовка делителей на карту-->

     <div v-for="del in delit" :key="del.id" >

      <ymap-marker 
      
      :marker-id='del.id' 
      :coords='[del.latitude, del.longitude]'
      :icon='bar8'
      :hint-content="del.address_del+ ' Делитель на ' +del.number_def"
     
    />

      </div>


<!--Вывод коммутаторов на карту-->

<div v-for="sw in swit" :key="sw.id">
      <ymap-marker 
      
      :marker-id='sw.id' 
      :coords='[sw.latitude, sw.longitude]'
      :icon='olt'
      :hint-content="sw.adr + ' . ' + sw.ip"
     
    />

      </div>


<!--Отображать электроопору с ящиком-->


<div v-for="op in opora" :key="op.id" >
<div v-if="op.type==1  & op.element=='ящик'">
<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:icon='depobox4'
:options="{preset: 'islands#nightStretchyIcon'}"
:properties="{iconContent: 'Ящик'}"
:hint-content="op.adres+ ' Номер опоры: ' +op.number"

/>

</div>
</div>

<!--Отображать электроопору с муфтой-->


<div v-for="op in opora" :key="op.id" >
<div v-if="op.type==1  & op.element=='муфта'">
<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:icon='depobox4'
:options="{preset: 'islands#violetStretchyIcon'}"
:properties="{iconContent: 'Муфта'}"
:hint-content="op.adres+ ' Номер опоры: ' +op.number"

/>

</div>
</div>


<!--Отображать депоопору-->


<div v-for="op in opora" :key="op.id" >
<div v-if="op.type==2">
<ymap-marker 

:marker-id='op.id' 
:coords='[op.latitude, op.longitude]'
:icon='depobox4'
:options="{preset: 'islands#blueMassTransitIcon'}"

:hint-content="op.adres+ ' Номер опоры: ' +op.number"

/>

</div>
</div>

      
     </yandex-map>
  
  </template>
  
  <script>
  
  
  const settings = {
        apiKey: 'c4928921-ad57-4083-a3e9-40a9288d9714',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      }
  
  
  import { yandexMap,ymapMarker } from 'vue-yandex-maps'
  import axios from 'axios'

  
  
  export default {
      data(){
          return {
              coords: [50.911979, 34.802763],//изначальные координаты карты
             isVistable:false,
              polylineGeometry:[],//массив магистралей
              index:0,
              maxRows:5,
              isVibor:true,//Отображать выбор вида портов
              mag: [],//массив магистралей
              delit:[],//массив клиентских делителей
              opora:[],//массив опор
              selectedValue: '',//ип адрес коммутатора
              portValue:'',//порт коммутатора
              port:1,
              isBdcom:false,
              isPort:true,// отображать порт при формировании базы магистралей
              isTransport:false,//отобрать карточку отправки данных
              ident_magistral:'',//идентификатор магистрали
              linename:'',//надпись на магистрали
              type:'',// Вид магистралей (1) - клиент (2) приход
              type_mag:'',//Вид магистралей буквенный
              bar8: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/editmap/deltmp.png'),
          imageSize: [43, 43],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:'',
          comand:''
        
               
        }, 
        swit:[], //массив коммутаторов,

        olt: {
          layout: 'default#imageWithContent',
          imageHref: require ('../images/img_maps/bdcom.png'),
          imageSize: [45, 42],
          imageOffset: [-16, -16],
          contentOffset: [0, 15],
          content:''
               
        }, 


          }

        },

        components: { yandexMap,ymapMarker},
        methods:{
          //Отображать или неотображать панель выбора портов

        vibor(v){
          if(v==1){this.isVibor=true,this.isBdcom=true}
          if(v==0){this.isVibor=false,this.isBdcom=false}

        },

        ed(e) {

          //Делаем видимой таблицу

          this.isVistable=true
  
          //Получаем координаты по щелку
  
          const cord=(e.get('coords'))
  
  
          this.show()
      //выбираем отдельно широту 
        
      const ln = cord[0];
  
      //выбираем отдельно долготу 
  
      const lg = cord[1];
  
      // Записываем данные в таблицу
      
      axios.get('https://billing.online.sumy.ua/control/phpfor/ins_mag_tmp.php', {
      
      params: {lat:ln,long:lg,type:this.type
        
    }
  
  })            
          .then(response => {
         
             
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })   
         
      },

      //Удаление

      delm(oper){

        axios.get('https://billing.online.sumy.ua/control/phpfor/del_map_tmp.php', {
      
      params: {oper:oper
        
    }
  
  })            
          .then(response => {


            this.show()
            
   
           
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  

      },

      //Просмотр магистрали
    
          show(){
              axios.get('https://billing.online.sumy.ua/control/phpfor/sel_mag_tmp.php', {
      
      params: {
        
    }
  
  })            
          .then(response => {
  
       //Создаем массив с координатами
  
            this.polylineGeometry = response.data.map((coord) => [coord.latitude, coord.longitude]);  
            this.mag=response.data
            console.log(this.portValue)
            this.sel_switch()
                    
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  
               
      },

//Выборка и отображение делителей на карте

      del_visual(){    
        
//Преобразование названий портов в числа
        
if(this.portValue=='Первый порт') {this.port=1}
if(this.portValue=='Второй порт') {this.port=2}
if(this.portValue=='Третий порт') {this.port=3}
if(this.portValue=='Четвертый порт') {this.port=4}


axios.get('https://billing.online.sumy.ua/control/phpfor/delit_map_tmp.php', {

 params: {host:this.selectedValue,port:this.port
}
})            
     .then(response => {
         
  this.delit=Object.keys(response.data).map(key=>{
           return {
             id:key,
             ...response.data[key]
           }
         })         
      
   })
   .catch(function (response) {
       //handle error
       console.log(response)
       
   });

},

//Выборка свича

sel_switch(){    
              
        
        axios.get('https://billing.online.sumy.ua/control/phpfor/switch.php', {
        
         params: {host:this.selectedValue
        }
        })            
             .then(response => {
                 
          this.swit=Object.keys(response.data).map(key=>{
                   return {
                     id:key,
                     ...response.data[key]
                   }
                 })      
                 
                 console.log(this.swit);
              
           })
           .catch(function (response) {
               //handle error
               console.log(response)
               
           });
        
        },


 //Вывод опор на карту       

show_opora(opr) {

axios.get('https://billing.online.sumy.ua/control/phpfor/sel_opora.php', {

params: {type:opr

}

})            
  .then(response => {


//Создаем массив с координатами

  this.opora=response.data
  console.log('opora',this.opora)


   
})
.catch(function (response) {
    //handle error
    console.log(response)
    
})  



      },

      //Перенос данных магистрали в таблицу map_magistral,info_magistral


      transport(){

if(this.type==''){alert('Поле вид магистрали не выбрано'),this.comand=''}
if(this.linename==''){alert('Поле имя магистрали не заполнено') ,this.comand=''}
if(this.selectedValue=='' & this.type!=2){alert('Поле коммутатор не заполнено'),this.comand=''}
if(this.portValue=='' & this.type!=2){alert('Поле порт не заполнено'),this.comand=''}

else {


this.comand='#exampleModal2'

//алгоритм генерации идентификатора магистрали

let abc = "1a2b3c4d5e6f7g8h9i0jklmnopqrstuvwxyz";
       this.ident_magistral = "";
      while (this.ident_magistral.length < 6) {
          this.ident_magistral += abc[Math.floor(Math.random() * abc.length)];
      }

this.isTransport=true

//-----------------------------------------------

// Определение типа магистрали прописью

if (this.type==1){this.type_mag='КЛИЕНТСКАЯ', this.isBdcom=true}
if (this.type==2){this.type_mag='ПРИХОД', this.isPort=false,this.isBdcom=false}

//-----------------------------------------

    }

},
           
    transport_insert(){

//Вставка информации по параметрам магистрали

axios.get('https://billing.online.sumy.ua/control/phpfor/infomag.php', {
      
      params: {ident_magistral:this.ident_magistral, //идентификатор магистрали
               name_magistral:this.linename, //Название магистрали
               type_magistral:this.type, //Вид магистрали
               color_magistral:this.linecolor, //цвет магистрали
               host:this.selectedValue, //коммутатор
               port:this.portValue //порт

        
    }
  
  })            
          .then(response => {

            alert('Вставка записи произведена')
            this.trs()
         
             
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })   
         

      },

      trs(){

        axios.get('https://billing.online.sumy.ua/control/phpfor/transport_map.php', {
      
      params: {ident_magistral:this.ident_magistral, //идентификатор магистрали
            
      
    }
  
  })            
          .then(response => {

          
         
             
        })
        .catch(function (response) {
            //handle error
            console.log(response)
            
        })  



      }

},

        }

  </script>
  
  <style scoped>
 
 .mytable {
  position: absolute;
  z-index: 2;
  margin-left: 75%;
  margin-top: 3%;
  height: 200px;
  overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
 }
 
 .mytable::-webkit-scrollbar {
      width: 0;
      height: 0;
}



 table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

thead {
  position: sticky;
  top: 0;
 
}

th {
  background-color:rgb(1, 1, 8);
  color: white;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

td, th {
  padding: 8px;
  border: 1px solid #ddd;
 
}

td {
  background-color:white;
}

.editnaw {
  position: absolute;
  z-index: 2;
  margin-left: 75%;
  margin-top: 14%;

}

button {
  margin-top: 3px;
  text-shadow: 1px 1px 1px black;
}

.transp {
  position: absolute;
  z-index: 2;
  margin-top: 10%;
  margin-left: 20%;
  box-shadow: 1px 1px 1px black;
}

.btn {
  margin-left: 2%;
}

  </style>